import React from "react";
import DataTable from "react-data-table-component";

export default function OnlineTrainings() {
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">SA</div>,
      selector: (row) => row.sa,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">Trained</div>,
      selector: (row) => row.trained,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">Trained On</div>,
      selector: (row) => row.trainedOn,
      sortable: true,
    },
  ];
  const data = [
    {
      sa: "ECD",
      trained: "NO",
      trainedOn: "",
    },
    {
      sa: "FIS",
      trained: "NO",
      trainedOn: "",
    },
  ];
  return (
    <DataTable columns={columns} data={data} pagination />
  );
}
