/* eslint-disable no-undef */
import { LogLevel } from "@azure/msal-browser";

console.log(process.env.REACT_APP_AZURE_CLIENT_ID, 'log')

export const msalConfig = {
    auth: {
        // eslint-disable-next-line no-undef
        clientId: `${process.env.REACT_APP_AZURE_CLIENT_ID}`, // This is the ONLY mandatory field that you need to supply.
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`, // Defaults to "https://login.microsoftonline.com/common"
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        clientCapabilities: ["CP1"] // this lets the resource owner know that this client is capable of handling claims challenge.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
        
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

export const protectedResources = {
    apiTodoList: {
        endpoint: "http://localhost:5000/api/todolist",
        scopes: {
            read: [ "api://Enter_the_Web_Api_Application_Id_Here/Todolist.Read" ],
            write: [ "api://Enter_the_Web_Api_Application_Id_Here/Todolist.ReadWrite" ]
        }
    }
}


export const loginRequest = {
    scopes: []
};