import React from 'react'
import PrimaryButton from '../../components/buttons/primary'
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../config/authConfig';


export default function Login() {
    const { instance } = useMsal();
    const login = () => {
      if(instance.controller && instance.controller.browserStorage){
        instance.controller.browserStorage.clear()
      }
      setTimeout(() => {
        instance.loginRedirect(loginRequest).then(() => {
          window.location.reload()
       })
       .catch((error) => console.log(error));
      }, 100);
       
    }
  return (
    <div className='h-screen flex items-center'>
        <div className='w-4/6 mx-auto my-auto bg-gray-100 shadow-md'>
            <div className='flex flex-col gap-10 items-center p-20'>
                    <p className='text-gray-800 text-center'>Welcome to HSRT Login Page</p>
                    <PrimaryButton onClick={login} className="w-[200px] px-2 py-3" label="Login"></PrimaryButton>
            </div>
        </div>
    </div>
  )
}
