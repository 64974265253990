import React from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

export default function HistoryTable() {
  const navigate = useNavigate();
  const handleChange = (val) => {
    navigate("/reviewers/change-form/" + val);
  };

  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW ID</div>,
      selector: (row) => row.reviewID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">Type</div>,
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW DATES</div>,
      selector: (row) => row.reviewDates,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">GRANTEE</div>,
      selector: (row) => row.grantee,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">COMP AREA</div>,
      selector: (row) => row.compArea,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW STATUS</div>,
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div
          className=" text-indigo-600 text-md"
          onClick={() => handleChange(row.statusID)}
        >
          {row.status}
        </div>
      ),
    }
  ];
  const data = [
    {
      reviewID:"067NE032",
      type:"Triennial",
      reviewDates:"04/02/24 - 04/07/24",
      grantee:"Goldenrod Hills Community Action Council, Inc",
      compArea:"HE",
      status:"",
      statusID:""
    },
    {
      reviewID:"053TR004",
      type:"HE/MCH Training",
      reviewDates:"06/27/24 - 06/29/24",
      grantee:"HE/MCH Training - Current Reviewers",
      compArea:"HE",
      status:"Change",
      statusID:"1234"
    },
  ];
  return (
    <DataTable columns={columns} data={data} pagination />
  );
}
