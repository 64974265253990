import React, { useState } from "react";
import Select from "../inputs/select";
import Input from "../inputs/input";
import DataTable from "react-data-table-component";
import PrimaryButton from "../buttons/primary";
import { useNavigate } from "react-router-dom";


const searchTypeForReviewerHistory = [
  {
    label: "Reviewer Name",
    value: "reviewerName",
  },
  {
    label: "Danya ID",
    value: "DanyaID",
  },
];

export default function ReviewerHistory() {
  const navigate = useNavigate();
  const [searchTypeRHistory, setSearchTypeRHistory] = useState("reviewerName");

  const handleRedirect = (val) => {
    navigate("/reviewers/details/" + val + '?tab=1');
  };

  const handleSearchTypeForRHistory = (e) => {
    setSearchTypeRHistory(e.target.value);
  };

  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">REVIEWER NAME</div>,
      selector: (row) => row.training,
      sortable: true,
      cell: (row) => {
        return <div  onClick={() => handleRedirect(row.reviewID)} className="text-indigo-600">{row.training}</div>;
      },
    },
    {
      name: <div className="uppercase text-md text-gray-600">DANYA ID</div>,
      selector: (row) => row.types,
      sortable: true,
    }
  ];
  const data = [
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "XXX-XX-0983",
      dates: "6/9/19-6/9/19",
    },
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "XXX-XX-0983",
      dates: "6/9/19-6/9/19",
    },
  ];

  return (
    <form className="flex flex-col gap-6">
      <div>
        <div className="flex gap-8">
          <div className="w-[200px]">
            <Select
              label="Search By"
              onChange={handleSearchTypeForRHistory}
              options={searchTypeForReviewerHistory}
            />
          </div>
          {searchTypeRHistory == "reviewerName" ? (
            <div className="w-[200px]">
              <Input label="Reviewer Name" />
            </div>
          ) : searchTypeRHistory === "DanyaID" ? (
            <div className="w-[200px]">
              <Input label="Danya ID" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="w-[170px]">
        <PrimaryButton label="Search" className={"px-3 py-2"} />
      </div>
      <div className="flex flex-col gap-6 text-left">
        <h6 className="text-[20px] font-[500]">Search Results</h6>
        <DataTable columns={columns} data={data} />
      </div>
    </form>
  );
}
