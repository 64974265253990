/* eslint-disable react/prop-types */
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";


export default function PrimaryTab({tabs, selected}) {
  const navigate = useNavigate();
  const location = useLocation()
  if(!(tabs && tabs.length > 0)){
    return null
  }
  const changeTab = (index) => {
    navigate(location.pathname+'?tab='+index)
  }

  const selectedTab = selected | 0
  
  return (
    <div className="w-full">
      <div className="flex gap-2">
        {tabs.map((tab, i) => {
          return (
        <div className={`text-[14px] p-2 cursor-pointer font-semibold ${selectedTab == i ? 'text-indigo-600 border-b-2 border-indigo-600 ' : ''}`} key={i} onClick={() => changeTab(i)}>
              {tab?.label}
            </div>
          );
        })}
      </div>
      <div>{tabs[selectedTab]?.tab}</div>
    </div>
  );
}
