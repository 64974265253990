/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as SearchSVG } from "../../assets/icons/search.svg";


const Search = ({ type, label, className, disabled,searchLabel, ...props }) => {
  return (
    <div className="flex flex-col gap-2">
      {label ? (
        <label className="text-text-secondary font-[400] text-[14px]">
          {label}
        </label>
      ) : (
        <></>
      )}

      <div className="relative flex items-center">
        <SearchSVG className="absolute left-3"/>
        <input
          type={type || "text"}
          disabled={disabled}
          {...props}
          className={` ${disabled ? "bg-gray-200" : "bg-white"} border px-2 pl-10 w-full  border-input-border  h-[46px] rounded-md shadow-sm ${className ? className : ""}`}
        />
        <button className="h-[46px] absolute bg-indigo-600 text-white right-0 px-3 rounded-r-[6px]">{searchLabel}</button>
      </div>
    </div>
  );
};

Search.prototype = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default Search;
