import React from "react";
import TextWithLabel from "../../../inputs/text";
import OnlineTrainings from "./online-trainings";

export default function View({reviewer}) {
  console.log(reviewer, 'reviewer')
  return (
    <div className="flex flex-col gap-10 text-left mb-20">
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="Danya ID" value={reviewer?.DanyaId} />
        </div>
      </div>
      <div className="flex gap-10">
        <div className="w-[88px]">
          <TextWithLabel label="Title"  value={reviewer?.Title} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="First Name"  value={reviewer?.FName} />
        </div>
        <div className="w-[88px]">
          <TextWithLabel label="MI"  value={reviewer?.MInitial}  />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Last Name"  value={reviewer?.LName} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Sufix" value={reviewer?.suffix} />
        </div>
      </div>
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="Company Name" value={reviewer?.company_name} />
        </div>
      </div>

      <div>
        <div className="flex flex-col gap-10">
          <div className="w-[200px]">
            <TextWithLabel label="Deficiency Status" value={reviewer?.deficient_status}/>
          </div>
          <div className="flex gap-10">
            <div className="w-[200px]">
              <TextWithLabel label="Reviewer Status" value={reviewer?.status} />
            </div>
            <div className="w-[200px]">
              <TextWithLabel label="Inactive Date" value={reviewer?.inactive_date} />
            </div>
            <div className="w-[200px]">
              <TextWithLabel label="Reason" value={""} />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[200px]">
              <TextWithLabel label="Home Address" value={reviewer?.HomeAddress1} />
            </div>
            <div className="w-[200px]">
              <TextWithLabel label="City" value={reviewer?.HomeCity} />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[200px]">
              <TextWithLabel label="State" value={reviewer?.HomeState} />
            </div>
            <div className="w-[200px]">
              <TextWithLabel label="Zip Code" value={reviewer?.HomeZip} />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[200px]">
              <TextWithLabel label="Home Phone/Fax " value={reviewer?.HomePhone} />
            </div>
            <div className="w-[200px]">
              <TextWithLabel label="Cellular" value={reviewer?.cellular_phone} />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[200px]">
              <TextWithLabel
                label="Email Address"
                value={reviewer?.email}
              />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[200px]">
              <TextWithLabel label="Vendor ID" value={reviewer?.mas_vendor_id} />
            </div>
            <div className="w-[200px]">
              <TextWithLabel label="Direct Deposit" value={reviewer?.direct_deposit} />
            </div>
            <div className="w-[200px]">
              <TextWithLabel label="NAV Vendor ID" value={reviewer?.NAV_Vendor_ID} />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[200px]">
              <TextWithLabel label="Reviewer Type" value={reviewer?.reviewer_type} />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-10">
        <div className="flex gap-10">
          <div className="w-[400px]">
            <TextWithLabel label="Employment Status" value={reviewer?.employment_status} />
          </div>
          <div className="w-[200px]">
            <TextWithLabel label="Fed/EHS. Grantee ID" value={reviewer?.granteeid_1} />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[400px]">
            <TextWithLabel
              label="Employer Name"
              value={reviewer?.employment_status}
            />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[200px]">
            <TextWithLabel label="Work Address" value={reviewer?.WorkAddress1} />
          </div>
          <div className="w-[200px]">
            <TextWithLabel label="City" value={reviewer?.WorkCity} />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[200px]">
            <TextWithLabel label="State" value={reviewer?.WorkState} />
          </div>
          <div className="w-[200px]">
            <TextWithLabel label="Zip Code" value={reviewer?.WorkZip} />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[200px]">
            <TextWithLabel label="Phone" value={reviewer?.WorkPhone} />
          </div>
          <div className="w-[200px]">
            <TextWithLabel label="Fax" value={reviewer?.WorkFax} />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-10">
        <div className="flex gap-10">
          <div className="w-[400px]">
            <TextWithLabel label="FedEx Address" value={reviewer?.FedexAddress1} />
          </div>
          <div className="w-[200px]">
            <TextWithLabel label="City" value={reviewer?.FedexCity} />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[200px]">
            <TextWithLabel label="State" value={reviewer?.FedexState} />
          </div>
          <div className="w-[200px]">
            <TextWithLabel label="Zip Code" value={reviewer?.FedexZip} />
          </div>
        </div>
      </div>
      <div className="flex- flex-col gap-10">
        <div className="flex gap-10">
          <div className="w-[200px]">
            <TextWithLabel label="Airport" value={reviewer?.Airport} />
          </div>
          <div className="w-[200px]">
            <TextWithLabel label="Service Area" value={""} />
          </div>
        </div>
      </div>
      <div className="fle flex-col gap-10">
        <div className="w-[400px]">
          <TextWithLabel label="Regions" value={""} />
        </div>
        <div className="w-[400px]">
          <TextWithLabel
            label="Test Status"
            value={""}
          />
          <TextWithLabel value={""} />
        </div>
      </div>
      <div className="flex flex-col gap-10">
        <TextWithLabel label="Online Trainings Fy 2011 (Mm/Dd/Yy)" />
        <OnlineTrainings />
      </div>
      <div className="flex flex-col gap-10">
        <div className="flex gap-10">
          <div className="w-[200px]">
            <TextWithLabel label="Exclude From" value={""} />
          </div>
          <div className="w-[200px]">
            <TextWithLabel label="Ytd Declines" value={""} />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[200px]">
            <TextWithLabel label="Home Region" value={reviewer?.home_region} />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[200px]">
            <TextWithLabel
              label="Email Address"
              value={reviewer?.email}
            />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[200px]">
            <TextWithLabel
              label="Work Email Address"
              value={reviewer?.workemail}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
