import React, {useState, useEffect} from "react";
// import Select from "../inputs/select";
// import Input from "../inputs/input";
// import PrimaryButton from "../buttons/primary";
import { useMsal } from '@azure/msal-react';
import ReviewSearch from "../review-search";
// import { post } from "../../services";

// const searchByOptions = [
//   {
//     label: "Review ID",
//     value: "review_id",
//   },
//   {
//     label: " Grantee ID",
//     value: "grantee_id",
//   },
//   {
//     label: " Grantee Name",
//     value: "grantee_name",
//   },
// ];

export default function Summary({summary}) {
  const { instance } = useMsal();
  const [name, setName] = useState("")
  // const [selectedOption, setSelectedOption] = useState(searchByOptions[0].value)
  // const [searchParams, setSearchParams] = useState({})

  useEffect(() => {
    if(instance){
      const account = instance.getActiveAccount();
      if(account){
        setName(account?.name)
      }
    }
  }, [])

  // const handleChangeOption =(e) => {
  //   console.log(e.target.value)
  //   if(e.target && e.target.value){
  //     setSelectedOption(e.target.value)
  //   }
  // }

  // const handleSearchParams = (e) => {
  //     if(e && e.target && e.target.name ){
  //       if(e.target.value){
  //         setSearchParams(prev => {
  //           return {...prev, [e.target.name] :e.target.value  }
  //         })
  //       }else{
  //         setSearchParams(prev => {
  //           return {...prev, [e.target.name] : ""  }
  //         })
  //       }
  //     }
  // }
  
  // const search = (e) => {
  //   if(e){
  //     e.preventDefault();

  //     var newParams = {}

  //     newParams[selectedOption] = searchParams[selectedOption]

  //     post('search', newParams).then(res => {
  //         console.log(res)
  //     }).catch(err => {
  //       console.error(err)
  //     })
  //   }
  // }
 

  return (
    <div className="flex flex-col gap-8 my-10">
      <div className="flex flex-col gap-6">
        <h5 className="font-semibold text-[20px]">Welcome back, {name}</h5>
        <p className="text-[14px] text-gray-500">
          You last visited this site
        </p>
      </div>
      <ReviewSearch />
      {/* <div className="flex flex-col gap-6">
        <h6 className="font-semibold text-[18px]">Review Search</h6>
        <div className="w-[200px]">
          <Select label="Search by" onChange={handleChangeOption} selected={selectedOption} options={searchByOptions} />
        </div>
        <form className="flex gap-6" onChange={handleSearchParams} onSubmit={search}>
          <div className="w-[200px]">
            {
              selectedOption === searchByOptions[0].value ? <Input label={searchByOptions[0].label} name={selectedOption} id={selectedOption} /> : <></>
            }
            {
              selectedOption === searchByOptions[1].value ? <Input label={searchByOptions[1].label} name={selectedOption} id={selectedOption} /> : <></>
            }
            {
              selectedOption === searchByOptions[2].value ? <Input label={searchByOptions[2].label} name={selectedOption} id={selectedOption} /> : <></>
            }
            
          </div>
          <PrimaryButton type="submit" label="Search" className="p-2 mt-auto" />
        </form>
      </div> */}
      <div className="flex gap-8">
        <div className="w-3/6 border border-gray-200 shadow-md p-5 flex flex-col gap-4 rounded-md">
          <h6 className="font-semibold text-[16px]">Recently Viewed</h6>
          <div className="flex flex-col gap-3">
            <ul className="list-disc px-10">
                <li className="font-semibold text-[14px]">Page URL</li>
                <li className="font-semibold text-[14px]">Page URL</li>
                <li className="font-semibold text-[14px]">Page URL</li>
                <li className="font-semibold text-[14px]">Page URL</li>
            </ul>
          </div>
        </div>
        <div className="w-3/6 border border-gray-200 shadow-md p-5 flex flex-col gap-4 rounded-md">
          <h6 className="font-semibold text-[16px]">Project Inbox</h6>
          <div className="flex flex-col gap-3">
          <ul className="list-disc px-10">
                <li className="font-semibold text-[14px]">New Reviews: {summary && summary.NewReviews ? summary.NewReviews : '0' }</li>
                <li className="font-semibold text-[14px]">Review Changes: {summary && summary.ReviewChanges ? summary.ReviewChanges : '0' }</li>
                <li className="font-semibold text-[14px]">Other: {summary && summary.Other ? summary.Other : '0' }</li>
                <li className="font-semibold text-[14px]">Not Submitted: {summary && summary.NotSumitted ? summary.NotSumitted : '0' }</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
