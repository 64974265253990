import React from "react";
import TextWithLabel from "../../../inputs/text";

export default function View() {
  return (
    <div className="flex flex-col gap-6 text-left mb-20">
      <div className="flex gap-6">
        <div className="w-[400px]">
          <TextWithLabel label="Hotel Name" value="Hampton Inn" />
        </div>
        <div className="w-[400px]">
          <TextWithLabel label="Address" value="43 Lake Street" />
        </div>
      </div>
      <div className="flex gap-6">
        <div className="w-[400px]">
          <TextWithLabel label="City" value="Geneva" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="State" value="NY" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Zip Code" value="14456" />
        </div>
      </div>
      <div className="flex gap-6">
        <div className="w-[400px]">
          <TextWithLabel label="County" value="Geneva" />
        </div>
        <div className="w-[400px]">
          <TextWithLabel label="Fax" value="(315) 781-2035" />
        </div>
      </div>
      <div className="flex gap-6">
        <div className="w-[200px]">
          <TextWithLabel label="Room Rate" value="$0.00" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Suite Rate" value="$0.00" />
        </div>
      </div>
      <div className="flex gap-6">
        <div className="w-[200px]">
          <TextWithLabel label="Check Out Time" value="-" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Special Instructions" value="-" />
        </div>
      </div>
      <div className="flex gap-6">
        <div className="w-[200px]">
          <TextWithLabel label="Email" value="-" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Contact" value="-" />
        </div>
      </div>
    </div>
  );
}
