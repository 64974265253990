import React from "react";
import DataTable from "react-data-table-component";

export default function CarExpenses() {
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">POV MILES</div>,
      selector: (row) => row.item,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">RATE PER MILE</div>,
      selector: (row) => row.rate,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">ENCUMBRANCE</div>,
      selector: (row) => row.encumbrance,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">CASH ADVANCE</div>,
      selector: (row) => row.cashAdvance,
      sortable: true,
    },
  ];
  const data = [
    {
      item: "Honoraria",
      rate: "$375.00",
      days: "4",
      encumbrance: "$1,500",
      cashAdvance: "$95.20",
    },
    {
      item: "M&I",
      rate: "$64",
      days: "1.75",
      encumbrance: "$112.00 X 0.85",
      cashAdvance: "",
    },
  ];
  return (
    <div className="p-7 flex flex-col gap-10">
      <div>
        <DataTable columns={columns} data={data} pagination />
      </div>
    </div>
  );
}
