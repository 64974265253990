import React from "react";
import HistoryTable from "./history-table";
import TextWithLabel from "../../../inputs/text";

export default function HotelHistory() {
  return (
    <div className="flex flex-col gap-10 text-left">
      <h6 className="font-[500] text-[18px] mt-5">History Of Hotel</h6>
      <div className="flex gap-6">
        <div className="w-[200px]">
          <TextWithLabel label="Times Booked" value="1" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Name" value="Hampton Inn" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Phone" value="315-781-2035" />
        </div>
        <div className="w-[400px]">
          <TextWithLabel
            label="Address"
            value="43 Lake Street, Geneva, NY 14456"
          />
        </div>
      </div>
      <h6 className="font-[500] text-[16px] mt-5">History Of Stays</h6>
      <HistoryTable />
    </div>
  );
}
