import React from "react";
import InputDate from "../inputs/date";
import PrimaryButton from "../buttons/primary";
import DataTable from "react-data-table-component";


export default function TrainingReviews() {

  const columns = [
    {
      name: <div className="uppercase text-md text-indigo-600">TRAINING ID</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">TRAINING</div>,
      selector: (row) => row.training,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">TYPES</div>,
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">DATES</div>,
      selector: (row) => row.dates,
      sortable: true,
    },
    
  ];
  const data = [
    {
      trainingID : "16STR008",
      training:"Software Training #7",
      types:"Focus Group",
      dates:"6/9/19-6/9/19"
    },
    {
      trainingID : "16STR008",
      training:"Software Training #7",
      types:"Focus Group",
      dates:"6/9/19-6/9/19"
    }
  ]
  return (
    <form className="flex flex-col gap-6">
      <div className="flex gap-8">
        <div className="w-[200px]">
          <InputDate label="Training Start Date" />
        </div>
        <div className="mt-auto">
          <PrimaryButton label="Search" className={"px-3 py-2"} />
        </div>
      </div>
      <div className="flex flex-col gap-6 text-left">
        <h6 className="text-[20px] font-[500]">Search Results</h6>
        <DataTable columns={columns} data={data} />
    </div>
    </form>
  );
}
