import React from 'react'
import DataTable from "react-data-table-component";


export default function CashAdvanceStatus() {
    const columns = [
        {
          name: <div className="uppercase text-md text-gray-600">PROCESS DATE</div>,
          selector: (row) => row.processDate,
          sortable: true,
        },
        {
          name: <div className="uppercase text-md text-gray-600">CHECK DATE</div>,
          selector: (row) => row.checkDate,
          sortable: true,
        },
        {
          name: <div className="uppercase text-md text-gray-600">CHECK #</div>,
          selector: (row) => row.checkID,
          sortable: true,
        },
        {
          name: <div className="uppercase text-md text-gray-600">AMOUNT</div>,
          selector: (row) => row.amount,
          sortable: true,
        },
        {
            name: <div className="uppercase text-md text-gray-600">CASH ADV</div>,
            selector: (row) => row.cashAdvance,
            sortable: true,
          },
          {
            name: <div className="uppercase text-md text-gray-600">MAILED DATE</div>,
            selector: (row) => row.mailedDate,
            sortable: true,
          },
      ];
      const data = [
        {
            processDate: "6/17/2010 1:58:32 PM",
            checkDate: "6/23/2010",
            checkID: "13706",
            amount:"$136.85",
            cashAdvance:"$136.85",
            mailedDate:"7/12/2010"
        }
      ];
  return (
    <div className="flex flex-col gap-10 text-left">
        <h6 className="text-[20px] font-[500]">Cash Advance Status</h6>
        <DataTable columns={columns} data={data} />
    </div>
  )
}
