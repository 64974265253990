import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import PrimaryTab from "../../components/tabs/primary";
import ReviewerViewEdit from "../../components/reviewers/tabs/view-edit";
import ReviewerHistory from "../../components/reviewers/tabs/reviewer-history";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from "../../components/typhography/title-primary";
import { get } from "../../services";

export default function ReviewerDetails() {
  const { id } = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const defaultTab = urlParams.get("tab");

  const [loading, setLoading] = useState(true);
  const [reviewers, setReviewers] = useState({});

  useEffect(() => {
    get(`reviewers/reviewer-details?reviewers_uid=${id}`)
      .then((res) => {
        if (res && res.length > 0) {
          setReviewers(res[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [id]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   post("reviewers/updateReviewer", reviewers)
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  const handleChange = (e) => {
    console.log(e.target, "e.target");
    if (e.target.name && e.target.value) {
      setReviewers((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else if (e.target.name) {
      setReviewers((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  if (loading) {
    return <div>...loading</div>;
  }

  const tabs = [
    {
      label: "View/Edit Details",
      tab: (
        <div className="mt-0">
          <div
            id="tabs-with-underline-1"
            role="tabpanel"
            aria-labelledby="tabs-with-underline-item-1"
          >
            {/* Form for viewing and editing */}
            <ReviewerViewEdit
              id={id}
              reviewer={reviewers}
              handleChange={handleChange}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Reviewer History",
      tab: (
        <div className="mt-0">
          <div
            id="tabs-with-underline-1"
            role="tabpanel"
            aria-labelledby="tabs-with-underline-item-1"
          >
            <ReviewerHistory id={id} reviewer={reviewers}/>
          </div>
        </div>
      ),
    },
  ];

  var reviewerName = "";

  if (reviewers.Title) {
    reviewerName += reviewers.Title;
  }

  if (reviewers.FName) {
    reviewerName += " " + reviewers.FName;
  }

  if (reviewers.LName) {
    reviewerName += " " + reviewers.LName;
  }

  return (
    <div className="flex flex-col gap-5">
      <PrimaryBreadCrumbs label="Reviewer Search" path="/reviewers" />

      <div className="flex justify-between">
        <TitlePrimary title={`${reviewerName ? reviewerName : id}`} />
      </div>
      <div>
        <PrimaryTab tabs={tabs} selected={defaultTab} />
      </div>

      {/* Separate Submit button outside form */}
      {/* <button onClick={handleSubmit} className="btn btn-primary">
        Save Changes
      </button> */}
    </div>
  );
}
