import React from "react";
import Input from "../../../inputs/input";
import PrimaryButton from "../../../buttons/primary";
import SecondaryButton from "../../../buttons/secondary";

export default function Edit({ setEdit }) {
  return (
    <div className="flex flex-col gap-10 text-left mb-20">
      <div className="flex flex-col gap-6 text-left">
        <div className="flex gap-6">
          <div className="w-[400px]">
            <Input label="Hotel Name" value="Hampton Inn" />
          </div>
          <div className="w-[400px]">
            <Input label="Address" value="43 Lake Street" />
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-[400px]">
            <Input label="City" value="Geneva" />
          </div>
          <div className="w-[200px]">
            <Input label="State" value="NY" />
          </div>
          <div className="w-[200px]">
            <Input label="Zip Code" value="14456" />
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-[400px]">
            <Input label="County" value="Geneva" />
          </div>
          <div className="w-[400px]">
            <Input label="Fax" value="(315) 781-2035" />
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-[200px]">
            <Input label="Room Rate" value="$0.00" />
          </div>
          <div className="w-[200px]">
            <Input label="Suite Rate" value="$0.00" />
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-[200px]">
            <Input label="Check Out Time" value="-" />
          </div>
          <div className="w-[200px]">
            <Input label="Special Instructions" value="-" />
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-[200px]">
            <Input label="Email" value="-" />
          </div>
          <div className="w-[200px]">
            <Input label="Contact" value="-" />
          </div>
        </div>
      </div>
      <div className="flex gap-2 mt-10">
        <SecondaryButton
          className={"py-3 px-10"}
          onClick={() => setEdit(false)}
          label={"Cancel"}
        />
        <PrimaryButton
          type="submit"
          className={"py-3 px-10"}
          label={"Update"}
        />{" "}
      </div>
    </div>
  );
}
