import React from "react";
import TextWithLabel from "../../../../inputs/text";

export default function Invoice() {
  return (
    <div className="p-7 flex flex-col gap-10">
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel
            label="Airline/Train Ticket Total Cost"
            value="$1,120.89"
          />
        </div>
        <div className="w-[200px]">
          <TextWithLabel
            label="Airline/Train Ticket Total Cost"
            value="$1,120.89"
          />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="E-Ticket" value="Yes" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Team Meeting" value="4/19/2016" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Exit Meeting" value="4/22/2016" />
        </div>
      </div>
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="Travel Mode" value="Rental Car" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Rental Car Agency" value="National" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Carsize" value="Compact" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Estimated Cost" value="$176.00" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Other Description" value="n/a" />
        </div>
      </div>
    </div>
  );
}
