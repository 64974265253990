import { SET_ROLES, SET_USER_INFO } from "./constants"

export const setRoles = (payload) => ({
   type: SET_ROLES,
   payload
})

export const setUserInfo = (payload) => ({
    type: SET_USER_INFO,
    payload
})