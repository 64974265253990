import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReviewsDataTable from "../../components/reviews/ReviewsDataTable";
import React, { useState } from "react";
import { URI_reviews } from "../../config/constants";
import Select from "../../components/inputs/select";
import { get } from "../../services";
import PrimaryModal from "../../components/modals/primary";
import PrimaryButton from "../../components/buttons/primary";
import SecondaryButton from "../../components/buttons/secondary";
import ReviewsDataTableIncomplete from "../../components/reviews/ReviewsDataTableIncomplete";
import { post } from "../../services";
import TitlePrimary from "../../components/typhography/title-primary";
import { useStore } from "../../store/index";
import ReviewsDataTableBeingCreated from "../../components/reviews/ReviewsDataTableBeingCreated";

const searchByOptions = [
  {
    label: "Review ID",
    value: "review_id",
  },
  {
    label: " Grantee ID",
    value: "grantee_id",
  },
  {
    label: " Grantee Name",
    value: "grantee_name",
  },
];

const Reviews = () => {
  const navigate = useNavigate();
  const { roles } = useStore();

  const [reviewsData, setReviewsData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchByType, setSearchByType] = useState("review_id");
  const [loading, setLoading] = useState(true);
  const [openReview, setReviewModal] = useState(false);
  const [incompleteReviews, setIncompleteReviews] = useState([]);
  const [createdReviews, setCreatedReviews] = useState([]);
  const [regions, setRegions] = useState([]);
  const [states, setStates] = useState([]);
  const [fys, setFYs] = useState([]);
  const [isCreateNewHasError, setCreatError] = useState(false)

  useEffect(() => {
    get(`reviews/being_created`)
      .then((res) => {
        if (res && res.length > 0) {
          setCreatedReviews(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
      get(`reviews/incomplete`)
      .then((res) => {
        if (res && res.length > 0) {
          setIncompleteReviews(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    Promise.all([
      get(`lookup/state_list`),
      get(`lookup/region_list`),
      get(`reviews/allowed_years`),
    ])
      .then(([st, reg, fy]) => {
        if (st && st.length > 0) {
          setStates(
            st
              .map((s) => {
                var temp = {};
                temp["label"] = s.Name;
                temp["value"] = s.Code;
                return temp;
              })
              .filter((d) => d.value != null)
          );
        }

        if (reg && reg.length > 0) {
          setRegions(
            reg.map((s) => {
              var temp1 = {};
              temp1["label"] = s.REGION;
              temp1["value"] = s.REGION;
              return temp1;
            })
          );
        }
        if (fy && fy.length > 0) {
          setFYs(
            fy.map((f) => {
              var temp1 = {};
              temp1["label"] = f.FISCAL_DISPLAY;
              temp1["value"] = f.FISCAL_YEAR;
              return temp1;
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSearchResults = () => {
    if (searchValue) {
      setLoading(true);

      get(`${URI_reviews}?${searchByType}=${searchValue}`)
        .then((res) => {
          console.log(res, "response");
          setReviewsData(res);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  const handleSearchValueChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchByType(e.target.value);
    setSearchValue("");
  };

  const createTraining = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formEntrries = Object.fromEntries(formData);

    post(`reviews/create_training`, formEntrries)
      .then((res) => {
        if (res && res.length > 0 && res[0].trip_id) {
          setCreatError(false)
          navigate(`/reviews/add/${res[0].trip_id}/${formEntrries.region}`);
        }else{
          setCreatError(true)
        }
      })
      .catch((err) => {
        console.error(err);
        setCreatError(true)
      });
  };

  const closeCreateNew = () => {
    setReviewModal(false)
    setCreatError(false)
  }

  const filterSearchByOption = searchByOptions.filter(
    (d) => d.value === searchByType
  );
  const searchByTypeLabel =
    filterSearchByOption.length > 0
      ? filterSearchByOption[0].label
      : "Review ID";

      console.log(roles, 'roles')


  return (
    <div className="flex flex-col gap-10">
      <PrimaryModal
        isOpened={openReview}
        handleModal={() => closeCreateNew()}
      >
        <div className="w-full flex flex-col gap-3 p-10">
          <form onSubmit={createTraining}>
            <div className="text-center">
              <TitlePrimary title={"Add a New Review"} />
            </div>
            <div className="flex flex-col gap-8 text-left w-5/12">
              <Select
                options={regions}
                label="Region"
                name="region"
                id="region"
              />
              <Select
                options={fys}
                label="Fiscal Year"
                name="fiscal_year"
                id="fiscal_year"
              />
              <Select
                options={states}
                label="State"
                name="state"
                id="state"
                maxLength="2"
              />
            </div>
            <div className="flex justify-center gap-2 mt-10">
              <SecondaryButton
                className={"py-3 px-10"}
                onClick={() => closeCreateNew()}
                label={"Cancel"}
              />
              <PrimaryButton
                type="submit"
                className={"py-3 px-10"}
                label={"Add New"}
              />{" "}
            </div>
            {
              isCreateNewHasError &&  <div className="flex items-center my-5">Something went wrong or there is no data</div>
            }
           
          </form>
        </div>
      </PrimaryModal>
      <div className="flex justify-between">
        <div className="text-justify font-bold text-3xl">Reviews</div>
        {roles && roles.includes("addReview") ? (
          <button
            type="button"
            onClick={() => setReviewModal(true)}
            className="py-2 px-10 align-end inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-custom-blue text-custom-blue hover:text-white hover:bg-custom-blue"
          >
            Add New
          </button>
        ) : (
          <></>
        )}
      </div>
      {roles && roles.includes("searchReview") ? (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-7">
            <h5 className="font-semibold text-left text-xl">Review Search</h5>
            <div className="flex gap-4 w-full items-center">
              <div className="flex flex-col gap-3">
                <label className="block text-sm font-medium text-gray-600  text-left">
                  Search By
                </label>
                <Select
                  onChange={(val) => handleSearchChange(val)}
                  options={searchByOptions}
                />
              </div>
            </div>
            <div className="flex gap-4 w-full items-center">
              <div className="flex flex-col gap-3">
                <label className="block text-sm font-medium text-gray-600  text-left">
                  {searchByTypeLabel}
                </label>
                <input
                  type="text"
                  value={searchValue}
                  onChange={(e) => handleSearchValueChange(e)}
                  className="py-3 px-4 block w-96 border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <button
                type="button"
                className=" mt-auto py-3 px-10 align-end inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-indigo-600 text-white hover:bg-gray-900"
                onClick={handleSearchResults}
              >
                Search
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-7">
            <h6 className="font-semibold text-left text-xl">Results ({reviewsData.length})</h6>
            {/* <label className="block text-sm text-gray-600  font-semibold text-left">
              {reviewsData.length} Results
            </label> */}
            <div className="flex flex-col">
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div>
                    {loading ? (
                      <div>...loading</div>
                    ) : (
                      <div>
                        <div className="border rounded-lg overflow-hidden dark:border-gray-700">
                          {" "}
                          {reviewsData && reviewsData.length > 0 ? (
                            <ReviewsDataTable reviewsData={reviewsData} isLinkDisabled={!(roles && roles.includes("changeReview"))} />
                          ) : (
                            <div>No Data. Try search something</div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div>
        {incompleteReviews && incompleteReviews.length > 0 && (roles && roles.includes("addReview")) ? (
          <div className="text-left">
            <h6 className="font-[500] text-[24px] my-5">
              Reviews Being Created ({createdReviews.length})
            </h6>
            <ReviewsDataTableBeingCreated reviewsData={createdReviews} />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div>
        {incompleteReviews && incompleteReviews.length > 0 && (roles && roles.includes("addReview")) ? (
          <div className="text-left">
            <h6 className="font-[500] text-[24px] my-5">
              Reviews Incomplete ({incompleteReviews.length})
            </h6>
            <ReviewsDataTableIncomplete reviewsData={incompleteReviews} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Reviews;
