import React from "react";
import { useParams } from "react-router-dom";
import TextWithLabel from "../../components/inputs/text";
import DataTable from "react-data-table-component";
import PrimaryButton from "../../components/buttons/primary";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from '../../components/typhography/title-primary'


export default function ProcessReviewsSendEmail() {
  const { id } = useParams();
  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log("Selected Rows: ", selectedRows);
  };

  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">REVIEWER</div>,
      selector: (row) => row.rate,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">EMAIL</div>,
      selector: (row) => row.rate,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">STATUS</div>,
      selector: (row) => row.cashAdvance,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase text-md text-gray-600">CA PROCESSED ON</div>
      ),
      selector: (row) => row.cashAdvance,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">EMAILED ON</div>,
      selector: (row) => row.cashAdvance,
      sortable: true,
    },
  ];
  const data = [
    {
      item: "test",
      rate: "test",
      days: "4",
      encumbrance: "$1,500",
      cashAdvance: "$95.20",
    },
    {
      item: "test",
      rate: "test",
      days: "1.75",
      encumbrance: "$112.00 X 0.85",
      cashAdvance: "",
    },
  ];

  return (
    <div className="flex flex-col my-10 text-left">
      <PrimaryBreadCrumbs label="Review Search" path="/reviews" />
      <TitlePrimary title={"Send Emails"} />
      <div className="mt-10 flex gap-6">
        <div className="w-[200px]">
          <TextWithLabel label="Review ID" value={id} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Review Date" value={"10/30-23 - 11/3/23"} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Grantee" value={"Kids Are First, Inc."} />
        </div>
      </div>
      <div className="mt-10">
        <DataTable
          columns={columns}
          data={data}
          pagination
          selectableRows
          onSelectedRowsChange={handleChange}
        />
      </div>
      <div>
        <PrimaryButton label="Send Email" className={"px-3 py-2"} />
      </div>
    </div>
  );
}
