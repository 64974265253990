import React from "react";
import { useLocation } from "react-router-dom";
import PrimaryTab from "../../components/tabs/primary";
import HotelViewEdit from "../../components/travel/hotel-tabs/view-edit/index";
import HotelHistory from "../../components/travel/hotel-tabs/hotel-history/index";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";


export default function HotelDetails() {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search);
  const defaultTab = urlParams.get('tab');

  const tabs = [
    {
      label: "View/Edit Details",
      tab: (
        <div className="mt-3">
          <div
            id="tabs-with-underline-1"
            role="tabpanel"
            aria-labelledby="tabs-with-underline-item-1"
          >
            <HotelViewEdit />
          </div>
        </div>
      ),
    },
    {
      label: "Hotel History",
      tab: (
        <div className="mt-3">
          <div
            id="tabs-with-underline-1"
            role="tabpanel"
            aria-labelledby="tabs-with-underline-item-1"
          >
            <HotelHistory />
          </div>
        </div>
      ),
    },
  ];
  return (
    <div className="flex flex-col gap-7">
      <PrimaryBreadCrumbs label="Hotel Search" path="/travel" />

      <div>
        <PrimaryTab tabs={tabs} selected={defaultTab}/>
      </div>
    </div>
  );
}
