import React from "react";
import Input from "../../inputs/input";
import TertiaryButton from "../../buttons/tertiary";
import InputDate from "../../inputs/date";
import Select from "../../inputs/select";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../buttons/primary";
import SecondaryButton from "../../buttons/secondary";

export default function UpdateAtCostLetterTab() {
  const navigate = useNavigate();
  const handleReviewID = (val) => {
    navigate("/reviews/details/" + val);
  };
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">Review ID</div>,
      selector: (row) => row.review_id,
      sortable: true,
      cell: (row) => (
        <div
          className=" text-indigo-600 text-md"
          onClick={() => handleReviewID(row.review_id)}
        >
          {row.review_id}
        </div>
      ),
    },
    {
      name: <div className="uppercase text-md text-gray-600">Hotel Nights</div>,
      selector: (row) => row.hotelNights,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">FTL</div>,
      selector: (row) => row.ftl,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">Hotel Name</div>,
      selector: (row) => row.hotelName,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">Country/City</div>,
      selector: (row) => row.country,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase text-md text-gray-600">Available Lodging</div>
      ),
      selector: (row) => row.availableLoding,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">Booked Rate</div>,
      selector: (row) => row.bookedRate,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">Recieved On</div>,
      selector: (row) => row.recievedOn,
      sortable: true,
    },
  ];
  const data = [
    {
      review_id: "01234F2C",
      hotelNights: "20",
      ftl: "Merlin Monro",
      hotelName: "The Line Hotel (Wilshire Plaza)",
      country: "Los Angeles",
      availableLoding: "$120",
      bookedRate: "$150",
      recievedOn: "2/04/2024",
    },
    {
      review_id: "01234FGD",
      hotelNights: "12",
      ftl: "Joe Lee",
      hotelName: "The Line Hotel (Wilshire Plaza) Test",
      country: "Los Angeles",
      availableLoding: "$250",
      bookedRate: "350",
      recievedOn: "2/04/2024",
    },
  ];
  return (
    <div className="mt-10 flex flex-col gap-8 text-left p-2">
      <h5 className="font-semibold text-[24px]">Update At Cost Letter</h5>
      <div className="flex gap-8">
        <div className="w-[192px]">
          <Input label="Review ID" />
        </div>
        <div className="mt-auto">
          <TertiaryButton className={"px-4 py-3"} label={"Validate Now"} />
        </div>
      </div>
      <div className="flex gap-8">
        <div className="w-[192px]">
          <InputDate label="Fiscal Year" />
        </div>
        <div className="w-[192px]">
          <Select options={[]} label="Region" />
        </div>
        <div className="mt-auto">
          <TertiaryButton className={"px-4 py-3"} label={"Validate Now"} />
        </div>
      </div>
      <div>
        <div className="w-[415px]">
          <Select options={[]} label="Select A Reviewer" />
        </div>
      </div>
      <div>
        <DataTable columns={columns} data={data} pagination />
      </div>
      <div className="flex gap-2 mt-6">
        <SecondaryButton className={"py-3 px-10"} label={"Cancel"} />
        <PrimaryButton
          type="submit"
          className={"py-3 px-10"}
          label={"Update"}
        />{" "}
      </div>
    </div>
  );
}
