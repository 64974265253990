import React from "react";
import TextWithLabel from "../../components/inputs/text";
import Input from "../../components/inputs/input";
import PrimaryButton from "../../components/buttons/primary";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from '../../components/typhography/title-primary'


export default function TerNotes() {

  return (
    <div className="flex flex-col gap-7 text-left">
      <PrimaryBreadCrumbs label="Enter TER" path="/ter" />
      <TitlePrimary title={"TER Notes"} />
      <div className="flex flex-col gap-6">
        <div className="flex gap-6">
          <div className="w-[400px]">
            <TextWithLabel
              label="Grantee"
              value="Kids Are First, Inc. / 166TXB4E"
            />
          </div>
          <div className="w-[400px]">
            <TextWithLabel label="Meeting Date" value="4/19/2016 - 4/22/2016" />
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-[400px]">
            <TextWithLabel label="FTL" value="Jennifer Johnston" />
          </div>
          <div className="w-[200px]">
            <TextWithLabel
              label="Reviewer"
              value="000-00-9713 Larry Kellogg
1520 West 1300 North
Farr West, UT 84404
(801) 686-2524"
            />
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-[400px]">
            <TextWithLabel label="TER Date" value="4/28/2016" />
          </div>
          <div className="w-[400px]">
            <TextWithLabel label="New TER Date" value="-" />
          </div>
        </div>
        <div className="">
          <div className="w-full">
            <Input type="textArea" label="Notes" />
          </div>
        </div>
      </div>
      <div>
      <PrimaryButton label="Update" className={"px-3 py-2"} />
      </div>
    </div>
  );
}
