import React, { useState, useEffect } from "react";
import TertiaryButton from "../../components/buttons/tertiary";
import Select from "../../components/inputs/select";
import Input from "../../components/inputs/input";
import CheckBoxGroup from "../../components/inputs/checkbox-group";
import PrimaryButton from "../../components/buttons/primary";
import SearchResults from "../../components/reviewers/search-results";
import TitlePrimary from '../../components/typhography/title-primary';
import { get } from "../../services";
import { useNavigate } from "react-router-dom";


const searchOptions = [
  {
    label: "Reviewer name",
    value: "name",
  },
  {
    label: "Vendor ID",
    value: "vendorID",
  },
  {
    label: "Review ID",
    value: "reviewID",
  },
  {
    label: "Grantee ID",
    value: "granteeID",
  },
  {
    label: "Service area",
    value: "serviceArea",
  },
  {
    label: "Email address",
    value: "email",
  },
  {
    label: "NFRTL",
    value: "nfrtlID",
  },
];

export default function Reviewers() {
  const [selectedOption, setOption] = useState("name");
  const [states, setStates] = useState([]);
  const [data, setData] = useState({});
  const[stateCode, setStateCode] = useState('ALL')
  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
      get('reviewers/stateSearch')
      .then(res => {
        if(res && res.length > 0){
          console.log(res, 'ress')
          const temp = res.map(r => {
            return {
              label:r?.Name,
              value:r?.Code
            }
          }).filter(d => d?.Name !== "ALL")
          console.log(temp, 'temp')
          setStates(temp)
        }
      })
      .catch(err => {
          console.error(err)
      })
  }, [])
  

  const handleOptions = (e) => {
    console.log(e.target.value);
    setOption(e.target.value);
  };

 const handleChange = (e) => {
    if (e.target.name && e.target.value) {
      if(e.target.type=="checkbox"){
        setData((prev) => {
          return { ...prev, [e.target.name]: e.target.checked };
        });
      }else{
        setData((prev) => {
          return { ...prev, [e.target.name]: e.target.value };
        });
      }
      
    } else if (e.target.name) {
      setData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    var tempData = {}
    if(selectedOption == "name"){
      tempData["firstName"] = data.firstName ? data.firstName : "",
      tempData["lastName"] = data.lastName ? data.lastName : ""
      tempData["stateCode"] = stateCode
      if(data?.excludeNonReviewers){
        tempData["excludeNonReviewers"] = data.excludeNonReviewers
      }
      if(data?.excludeInactive){
         tempData["excludeInactive"] = data.excludeInactive
      }
      if(data?.excludeAdminHold){
         tempData["excludeAdminHold"] = data.excludeAdminHold
    }

    var url = 'reviewers/search'
    Object.keys(tempData).forEach((key, i) => {
      if(i ===0){
        url += `?${key}=${tempData[key]}`
      }else{
        url += `&${key}=${tempData[key]}`   
      }
      
    })

    console.log(url, 'params')

    get(url)
    .then(res => {
      console.log(res, 'raksjvahsas')
      if(res && res.length > 0){
        setSearchData(res)
      }
    })
    .catch(err => {
      console.error(err)
    })
  }
}

const handleChangeStateCode = (e) => {
    if(e.target&& e.target.value && e.target.value != "  "){
      setStateCode(e.target.value)
    }else{
      setStateCode("ALL")
    }
}

  return (
    <div className="flex flex-col gap-8 my-10">
      <div className="flex items-center">
      <TitlePrimary title={"Reviewers"} />
        <div className="ml-auto">
          <TertiaryButton label={"Add New"} onClick={() => navigate('/reviewers/add')} className={"px-4 py-2"} />
        </div>
      </div>
      <form className="flex flex-col gap-10 text-left" onChange={handleChange} onSubmit={handleSubmit}>
        <h6 className="font-semibold text-[18px] m-0 p-0">Reviewer Search</h6>
        <div className="w-[200px]">
          <Select
            onChange={handleOptions}
            options={searchOptions}
            label="Search By"
            selected={selectedOption}
          />
        </div>
        {selectedOption === "name" ? (
          <div className="flex flex-col gap-10">
            <div className="flex gap-10">
              <div className="w-[200px]">
                <Input label="First Name" name="firstName" id="firstName" value={data?.firstName} />
              </div>
              <div className="w-[200px]">
                <Input label="Last Name" name="lastName" id="lastName"   value={data?.lastName} />
              </div>
            </div>
            <div>
              <CheckBoxGroup
                label="Exclude"
                groups={[
                  {
                    label: "Non-Reviewers",
                    value: true,
                    name: "excludeNonReviewers",
                    id:'excludeNonReviewers'
                  },
                  {
                    label: "Inactive Reviewers",
                    value: true,
                    name: "excludeInactive",
                    id:'excludeInactive'
                  },
                  {
                    label: "Admin Hold Reviewers",
                    value: true,
                    name: "excludeAdminHold",
                    id:'excludeAdminHold'
                  },
                ]}
              />
            </div>
            <div className="w-[200px]">
              <Select options={states} name="state" selected={stateCode} id="state" onChange={handleChangeStateCode} label="Search By State" />
            </div>
          </div>
        ) : selectedOption === "vendorID" ? (
          <div className="w-[200px]">
            <Input label="Vendor ID" value="" />
          </div>
        ) : selectedOption === "reviewID" ? (
          <div className="w-[200px]">
            <Input label="Review ID" value="" />
          </div>
        ) : selectedOption === "granteeID" ? (
          <div className="w-[200px]">
            <Input label="Grantee ID" value="" />
          </div>
        ) : selectedOption === "serviceArea" ? (
          <div className="flex flex-col gap-10">
            <div>
              <CheckBoxGroup
                label="Service Area"
                groups={[
                  {
                    label: "ECD",
                    value: "ECD",
                  },
                  {
                    label: "HEA",
                    value: "HEA",
                  },
                ]}
              />
            </div>
            <div>
              <CheckBoxGroup
                label="Exclude"
                groups={[
                  {
                    label: "Non-Reviewers",
                    value: "Non-Reviewers",
                  },
                  {
                    label: "Inactive Reviewers",
                    value: "Inactive Reviewers",
                  },
                  {
                    label: "Admin Hold Reviewers",
                    value: "Admin Hold Reviewers",
                  },
                ]}
              />
            </div>
            <div className="w-[200px]">
              <Select options={states} label="Search By State" />
            </div>
          </div>
        ) : selectedOption === "email" ? (
          <div className="w-[200px]">
            <Input label="Email Address" value="" />
          </div>
        ): selectedOption === "nfrtlID" ? (
          <div className="w-[200px]">
            <Input label="NFRTL" value="" />
          </div>
        ): (
          <></>
        )}

        <div>
          <PrimaryButton type="submit" label="Search" className={"px-3 py-2"} />
        </div>
      </form>
      <div className="flex flex-col gap-10 text-left mt-10">
        <h6 className="font-semibold text-[18px] m-0 p-0">Search Results ( {searchData.length ? searchData.length : 0} )</h6>
        <SearchResults data={searchData}/>
      </div>
    </div>
  );
}
