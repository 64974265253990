import React from "react";
import PrimaryButton from "../buttons/primary";
import Input from "../inputs/input";
import TertiaryButton from "../buttons/tertiary";
import TextWithLabel from "../inputs/text";
import DataTable from "react-data-table-component";

export default function ExpenseInquiry() {
  const columns = [
    {
      name: (
        <div className="uppercase text-md text-indigo-600">TRAINING ID</div>
      ),
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW ID</div>,
      selector: (row) => row.training,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">TYPE</div>,
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">TER RECEIVED</div>,
      selector: (row) => row.dates,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">NEW TER DATE</div>,
      selector: (row) => row.dates,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">CHECK DATE</div>,
      selector: (row) => row.dates,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">CHECK AMOUNT</div>,
      selector: (row) => row.dates,
      sortable: true,
    },
  ];
  const data = [
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
    },
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
    },
  ];
  return (
    <form className="flex flex-col gap-6">
      <div className="flex gap-8">
        <div className="w-[200px]">
          <Input label="Review ID" />
        </div>
        <div className="mt-auto">
          <PrimaryButton label="See Data" className={"px-3 py-2"} />
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex gap-8">
          <h6 className="font-[500] text-[18px]">MaryAnn Alger</h6>
          <div className="w[200px] ml-auto">
            <TertiaryButton label="Export as PDF" className={"px-3 py-2"} />
          </div>
        </div>
        <div className="flex gap-8">
          <div className="w-400">
            <TextWithLabel
              label="Address"
              value="3839 Vista Campana S #1, Oceanside, CA 92057"
            />
          </div>
          <div className="w-200">
            <TextWithLabel label="Home Phone" value="(503) 348-9959" />
          </div>
          <div className="w-200">
            <TextWithLabel label="Work Phone" value="" />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-10 text-left">
        <h6 className="text-[20px] font-[500]">Review History</h6>
        <DataTable columns={columns} data={data} />
      </div>
    </form>
  );
}
