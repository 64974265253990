/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Input from "../../components/inputs/input";
import { URI_grantees } from "../../config/constants";
import PrimaryButton from "../../components/buttons/primary";
import Table from "../../components/table";
import { useNavigate } from "react-router-dom";
import { get } from "../../services";
import dayjs from "dayjs";
import TitlePrimary from '../../components/typhography/title-primary'


const Hotels = () => {
  const [searchParams, setSearchParams] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target && e.target.value && e.target.name) {
      setSearchParams((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }else{
      setSearchParams((prev) => {
        return {  };
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    if(searchParams && Object.keys(searchParams).length > 0){
      const inputKey = Object.keys(searchParams)[0];
      const inputValue = searchParams[inputKey];

      get(`${URI_grantees}?${inputKey}=${inputValue}`).then(res => {
        console.log(res, 'response')
        setSearchResults(res)
        setLoading(false)

      }).catch(err => {
        console.error(err)
        setLoading(false)
      })

    }else{
      console.error('no values to Submit. pls eneter something')
    }




  };

  const handleGranteeId = (id) => {
    navigate("/grantees/details/" + id);
  };

  const columns = [
    {
      name: (
        <div className="uppercase text-md text-gray-600">Grantee ID</div>
      ),
      selector: (row) => row.fed_grantee_id,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">GRANTEE NAME</div>,
      sortable: true,
      cell: (row) => (
        <div
          className=" text-indigo-600 text-md cursor-pointer"
          onClick={() => handleGranteeId(row.grantee_uid)}
        >
          {row.Name}
        </div>
      )
    },
    {
      name: <div className="uppercase text-md text-gray-600">CITY, STATE</div>,
      cell: (row) => (
        <div>
          {row.City}, {row.State}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="uppercase text-md text-gray-600">LAST ACTIVITY</div>
      ),
      selector: (row) => row.CreateTs,
      sortable: true,
      cell:(row) => {
        if(row && row.CreateTs){
          return<div>{dayjs(row.CreateTs).format("YYYY/MM/DD")}</div>
        }else{
          return <div>-</div>
        }
      }
    },
  ];

  return (
    <div>
      <div className="flex flex-col gap-4 text-left my-6">
      <TitlePrimary title={"Grantee Search"} />
        <form
          onSubmit={handleSubmit}
          onChange={handleChange}
          className="flex flex-col gap-4"
        >
          <div className="flex flex-col gap-4">
            <div>
              <Input
                type="text"
                label=" Grantee Name"
                name="grantee_name"
                className="w-[400px]"
                disabled={searchParams["grantee_id"] || searchParams["review_id"]}
              />
            </div>
            <div className="flex gap-4">
              <div>
                <Input
                  type="text"
                  label="Grantee ID"
                  name="grantee_id"
                  className="w-[192px]"
                  disabled={searchParams["grantee_name"] || searchParams["review_id"]}
                />
              </div>
              <div>
                <Input
                  type="text"
                  label="Review ID"
                  name="review_id"
                  className="w-[192px]"
                  disabled={searchParams["grantee_name"] || searchParams["grantee_id"]}
                />
              </div>
            </div>
            <div className="mt-3">
              <PrimaryButton
                type="submit"
                label="Search Now"
                className="w-[126px] h-[46px]"
              />
            </div>
          </div>
        </form>
      </div>
      {
        loading ? <div>...searching</div> : searchResults && searchResults.length > 0 ? <div>
          <div className = "flex justify-start mb-5">{"Search Results (" + searchResults.length + ")"}</div>
          <Table columns={columns} data={searchResults} />
        </div> : <div>No Data. Try search something</div>
       
      }
   
    </div>
  );
};

export default Hotels;
