import React from "react";
import TextWithLabel from "../../../inputs/text";
import HistoryTable from "./history-table";

export default function ReviewerHistory() {
  return (
    <div className="flex flex-col gap-10 text-left">
      <h6 className="font-[500] text-[18px] mt-5">Reviewer History</h6>
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="Danya ID" value="XXX-XX-5543" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Name" value="Ms Susan M Aichele" />
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <h6 className="font-[500] text-[16px]">Current Schedules</h6>
        <div>No data to display</div>
      </div>
      <div className="flex flex-col gap-6">
        <h6 className="font-[500] text-[16px]">Being Tracked</h6>
        <div>No data to display</div>
      </div>
      <div className="flex flex-col gap-6">
        <h6 className="font-[500] text-[16px]">History</h6>
        <div>N<HistoryTable /></div>
      </div>
    </div>
  );
}
