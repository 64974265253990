import React from "react";
import TextWithLabel from "../../../../inputs/text";

export default function TravelerInfo() {
  return (
    <div className="p-7 flex flex-col gap-10">
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="SSN" value="xxx-xx-9713" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Traveler" value="Mr. Larry Kellogg" />
        </div>
        <div className="w-[400px]">
          <TextWithLabel
            label="Traveler's Address"
            value="1520 West 1300 North Farr West, UT 8804"
          />
        </div>
      </div>
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="Home" value="(801) 686-2524" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Work Phone" value="(000) 000-0000" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Date Of Request" value="4/19/2016" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Date Of Check Needed" value="4/19/2016" />
        </div>
      </div>
    </div>
  );
}
