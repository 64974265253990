/* eslint-disable react/prop-types */
import React from "react";
import DataTable from "react-data-table-component";

const Table = ({ data, columns }) => {
  return <DataTable columns={columns} data={data} pagination />;
};


export default Table;
