import React from "react";
import TextWithLabel from "../inputs/text";

export default function ForDanyaUse() {
  return (
    <div className="flex flex-col gap-10 text-left">
      <h6 className="text-[20px] font-[500]">For Danya Use Only</h6>
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="Check Received On" value="-" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Personal/Danya Check" value="-" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Check#" value="-" />
        </div>
      </div>
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="Check Amount" value="-" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="To Accounting" value="-" />
        </div>
      </div>
    </div>
  );
}
