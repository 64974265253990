/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

const InputToggle = ({ label, onClick, checked }) => {
  return (
    <div className="flex flex-col gap-2 relative">
      
      {label ? (
        <label
          htmlFor="toggle"
          className="text-text-secondary font-[400] text-[14px]"
        >
          {label}
        </label>
      ) : (
        <></>
      )}
      <input
        type="checkbox"
        id="toggle"
        checked={checked}
        className="absolute left-1/2 -translate-x-1/2  peer appearance-none rounded-md"
      />
      <button onClick={() => onClick()} className="w-12 h-6 flex items-center flex-shrink-0 ml-3 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-purple-400 after:w-5 after:h-5 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-6 group-hover:after:translate-x-1"></button>
    </div>
  );
};

InputToggle.prototype = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default InputToggle;
