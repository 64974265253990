import React from "react";
import DataTable from "react-data-table-component";

export default function HistoryTable() {

  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW ID</div>,
      selector: (row) => row.reviewID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">GRANTEE NAME</div>,
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">MEETING DATES</div>,
      selector: (row) => row.reviewDates,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">EXIT DATE</div>,
      selector: (row) => row.grantee,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEWERS</div>,
      selector: (row) => row.compArea,
      sortable: true,
    }
  ];
  const data = [
    {
      reviewID:"067NE032",
      type:"Triennial",
      reviewDates:"04/02/24 - 04/07/24",
      grantee:"Goldenrod Hills Community Action Council, Inc",
      compArea:"HE",
      status:"",
      statusID:""
    },
    {
      reviewID:"053TR004",
      type:"HE/MCH Training",
      reviewDates:"06/27/24 - 06/29/24",
      grantee:"HE/MCH Training - Current Reviewers",
      compArea:"HE",
      status:"Change",
      statusID:"1234"
    },
  ];
  return (
    <DataTable
    columns={columns}
    data={data}
    expandableRows={true}
    expandableRowsComponent={ExpandedComponent}
  />
  );
}



const ExpandedComponent = () => {
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">STATUS</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEWER</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">EMAIL</div>,
      selector: (row) => row.training,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">FIRST NIGHT</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">LAST NIGHT</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">BOOKED RATE</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase text-md text-gray-600">
         TAXES
        </div>
      ),
      selector: (row) => row.dates,
      sortable: true,
    },
  ];
  const data = [
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
    },
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
    },
  ];

  return <div className="px-12 bg-[#F1F5F9] subtable-conatiner">
    <DataTable columns={columns} data={data} />
  </div>;
};
