/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from 'prop-types'


const TextWithLabel = ({ label,value, className }) => {
  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      {label ? (
        <label className="text-text-secondary font-[400] text-[14px]">
          {label}
        </label>
      ) : (
        <></>
      )}
      {
        value ? <p className="font-semibold text-[14px]">{value}</p> : <></>
      }
      
    </div>
  );
};


TextWithLabel.prototype = {
    value : PropTypes.string,
    label: PropTypes.string
}

export default TextWithLabel;
