import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";

import AccordianPrimary from "../../accordian/primary";
import ReviewInfoEdit from "../reviewInfo/edit";
import ReviewInfoView from "../reviewInfo/view";
import PrimaryButton from "../../buttons/primary";
import SecondaryButton from "../../buttons/secondary";
import { post, get } from "../../../services";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import Select from "../../inputs/select";
import TertiaryButton from "../../buttons/tertiary";
import AccordianAction from "../../accordian/accordian-action";

import { toast, Bounce } from "react-toastify";

/* eslint-disable react/prop-types */
const GranteeInfo = ({ granteeInfo }) => {
  const getModifiedData = (str) => {
    return str && str != null && str != undefined ? str : ""
  }

  return (
    <div className="flex flex-row justify-between py-4">
      <div className="flex flex-col gap-8 px-20 text-left">
        <div>
          <div>Grantee ID</div>
          <div>{getModifiedData(granteeInfo.fed_grantee_id)}</div>
        </div>
        <div>
          <div>Grantee</div>
          <div>{getModifiedData(granteeInfo.Name)}</div>
        </div>
        <div>
          <div>Address</div>
          <div>{getModifiedData(granteeInfo.Address) + " " + getModifiedData(granteeInfo.Address2)}</div>
        </div>
        <div>
          <div>Phone</div>
          <div>{formatPhoneNumber(granteeInfo.Phone)}</div>
        </div>
        <div>
          <div>Fax</div>
          <div>{formatPhoneNumber(granteeInfo.Fax)}</div>
        </div>
      </div>
      <div className="flex flex-col gap-8 px-20 text-left">
        <div>
          <div>Contact</div>
          <div>{getModifiedData(granteeInfo.Contact)}</div>
        </div>
        <div>
          <div>Email</div>
          <div>{getModifiedData(granteeInfo.email)}</div>
        </div>
        <div>
          <div>Region</div>
          <div>{getModifiedData(granteeInfo.Region)}</div>
        </div>
        <div>
          <div>County</div>
          <div>{getModifiedData(granteeInfo.County)}</div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const ViewEditDetailTab = ({ reviewInfo }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isEditEnabled, setEdit] = useState(false);

  const [accordianAction, setAccordianAction] = useState("expandAll");
  // eslint-disable-next-line react/prop-types
  const [reviewInfoData, setReviewInfo] = useState(
    reviewInfo?.reviewInfo?.length ? reviewInfo.reviewInfo[0] : {}
  );
  const [statusLists, setStatusList] = useState([]);
  /* eslint-disable react/prop-types */
  const granteeInfoData = reviewInfo?.granteeInfo?.length
    ? reviewInfo?.granteeInfo[0]
    : {};
  const hotelInfoData = reviewInfo?.hotelInfo?.length
    ? reviewInfo?.hotelInfo
    : [];
  const reviewerInfoData = reviewInfo?.reviewerInfo?.length
    ? reviewInfo?.reviewerInfo
    : {};
  const [errorFields, setErrorFields] = useState({});


  useEffect(() => {
    get("reviews/status_list")
      .then((res) => {
        if (res && res.length > 0) {
          setStatusList(
            res
              .map((s) => {
                var temp = {};
                temp["label"] = s.Team_Completion_Desc;
                temp["value"] = s.Team_Completion_Status;
                return temp;
              })
              .filter((d) => d.value != null)
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const HotelInfoTable = ({ hotelInfo }) => {
    console.log(hotelInfo, "hotelinfo");
    const columns = [
      {
        name: <div className="uppercase text-md text-gray-600">Hotel Name</div>,
        selector: (row) => row.hotelName,
        cell: (row) => {
          return (
            <span
              onClick={() =>
                navigate(
                  `/reviews/${id}/hotel/${row.id}/${isEditEnabled ? "edit" : "view"}`
                )
              }
              className="text-indigo-600 font-semibold cursor-pointer"
            >
              {row.hotelName}
            </span>
          );
        },
        sortable: true,
      },
      {
        name: (
          <div className="uppercase text-md text-gray-600">Date Booked</div>
        ),
        selector: (row) => row.bookedDate,
        sortable: true,
      },
    ];

    const hotelInfoList = { accounting: [] };
    // Safely access recordsets and map over them if they exist
    if (hotelInfo && hotelInfo.length) {
      (hotelInfo || []).forEach((data) => {
        hotelInfoList.accounting.push({
          hotelName: data.Name,
          bookedDate: data.hotel_nights,
          id: data.hotel_uid,
        });
      });
    }

    return (
      <DataTable columns={columns} data={hotelInfoList.accounting} pagination />
    );
  };

  const ReviewersInfoTable = ({ reviewerInfo }) => {
    const columns = [
      {
        name: <div className="uppercase text-md text-gray-600">Reviewer</div>,
        selector: (row) => (
          <span className="text-md font-semibold">
            {row.reviewer}
          </span>
        ),
        sortable: true,
      },
      {
        name: (
          <div className="uppercase text-md text-gray-600">Service Area I</div>
        ),
        selector: (row) => row.serviceArea1,
        sortable: true,
      },
      {
        name: (
          <div className="uppercase text-md text-gray-600">Service Area II</div>
        ),
        selector: (row) => row.serviceArea2,
        sortable: true,
      },
      {
        name: (
          <div className="uppercase text-md text-gray-600">Travel Mode</div>
        ),
        selector: (row) => row.travelMode,
        sortable: true,
      },
      {
        name: <div className="uppercase text-md text-gray-600">DD</div>,
        selector: (row) => row.isDD,
        sortable: true,
      },
    ];

    const reviewerInfoList = { accounting: [] };

    // Safely access recordsets and map over them if they exist
    if (reviewerInfo && reviewerInfo.length > 0) {
      (reviewerInfo || []).forEach((data) => {
        reviewerInfoList.accounting.push({
          reviewer: data.rev_name,
          serviceArea1: data.ComponentArea,
          serviceArea2: data.componentAreaII,
          travelMode: data.TravelMode,
          isDD: data.hoteName,
          action: data.hoteName,
        });
      });
    }

    return <DataTable columns={columns} data={reviewerInfoList.accounting} />;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(reviewInfoData);
    var tempReviewInfo = { ...reviewInfoData };
    console.log("temp review info", reviewInfoData)
    tempReviewInfo["review_id"] = id;
    tempReviewInfo["UserId"] = "HEADSTART";
    tempReviewInfo["kids_served"] = "0";
    tempReviewInfo["team_completed"] = "null";
    tempReviewInfo["trip_status"] = "null";
    tempReviewInfo["MeetingPlace"] = reviewInfoData?.MeetingPlace ?? "";
    tempReviewInfo["MeetingDate"] = dayjs(reviewInfoData?.MeetingDate).format('YYYY-MM-DD') ?? "";
    tempReviewInfo["ExitDate"] = dayjs(reviewInfoData?.ExitDate).format('YYYY-MM-DD') ?? "";
    tempReviewInfo["arrival_city"] = reviewInfoData?.arrival_city ?? "";
    tempReviewInfo["arrival_date"] = dayjs(reviewInfoData?.arrival_city).format('YYYY-MM-DD') ?? "";
    tempReviewInfo["arrival_time"] = dayjs(reviewInfoData?.arrival_time) ?? "";
    tempReviewInfo["departure_date"] = dayjs(reviewInfoData?.departure_date).format('YYYY-MM-DD') ?? "";
    tempReviewInfo["departure_time"] = dayjs(reviewInfoData?.departure_time) ?? "";
    tempReviewInfo["departure_city"] = reviewInfoData?.departure_city ?? "";

    const errorMapFields = {
      MeetingPlace: 'Meeting Place',
      Meetingtime: 'Meeting Time',
      MeetingDate: 'Meeting Date',
      ExitTime: 'Exit Time',
      ExitDate: 'Exit Date',

      arrival_city: 'Arrival City',
      arrival_date: 'Arrival Date',
      departure_city: 'Departure City',
      arrival_time: 'Aarrival Time',
      departure_time: 'Departure Time',
      departure_date: 'Departure Date',
      FTL_UID: "RTL"
    }
    // let errorFieldDetails = {};

    // if (!tempReviewInfo['MeetingDate']) {
    //   errorFieldDetails['MeetingDate'] = `Error - Meeting Date is Required`
    // }
    // if (!tempReviewInfo['Meetingtime']) {
    //   errorFieldDetails['Meetingtime'] = `Error - Meeting Time is Required`
    // }
    // if (!tempReviewInfo['MeetingPlace']) {
    //   errorFieldDetails['MeetingPlace'] = `Error - Meeting Place is Required`
    // }
    // if (!tempReviewInfo['ExitDate']) {
    //   errorFieldDetails['ExitDate'] = `Error - Exit Date is Required`
    // }
    // if (!tempReviewInfo['ExitTime']) {
    //   errorFieldDetails['ExitTime'] = `Error - Exit Time is Required`
    // }
    const getDateValidationError = (dateOne, dateTwo, itemOne, itemTwo) => {
      console.log("dateValidations", !dateOne, !dateTwo)
      if (dateTwo) {

        const date1 = new Date(dateOne);
        const date2 = new Date(dateTwo);
        const diffTime = date2 - date1;
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const diffMonths = Math.floor(diffDays / 30);

        console.log("diff months as follows", dateOne, dateTwo, diffMonths)

        if (diffMonths > 12) {
          return { [itemTwo]: `Error - ${itemOne} should be with in Fiscal Year` }
        }
        if (diffDays < 0 && itemTwo) {
          return { [itemOne]: `Error - ${itemOne} should be prior to ${itemTwo}` }
        }
        if (!dayjs(dateOne, 'YYYY-MM-DD', true).isValid()) {
          return `Error - Invalid ${itemOne} `;
        }
      }
      return '';
    }
    const allErrorFields = {}
    console.log("tempReviewInfo Fields as follows", tempReviewInfo)
    Object.keys(errorMapFields).map(item => {
      if (!tempReviewInfo[item]) {
        allErrorFields[item] = `Error - ${errorMapFields[item]} is required`
      } else if (typeof tempReviewInfo[item] === 'string' && tempReviewInfo[item].trim().length === 0) {
        allErrorFields[item] = `Error - ${errorMapFields[item]} is required`
      } else if (typeof tempReviewInfo[item] === 'string' && tempReviewInfo[item].includes("'")) {
        allErrorFields[item] = `Error - ${errorMapFields[item]} contains illegal character(').`
      } else {
        if (item === 'MeetingDate' || item === 'ExitDate') {
          let errorStr = getDateValidationError(tempReviewInfo['MeetingDate'], tempReviewInfo['ExitDate'], errorMapFields['MeetingDate'], errorMapFields['ExitDate']);
          console.log("diff months as follows error", errorStr)
          if (errorStr[errorMapFields['MeetingDate']]) {
            allErrorFields['MeetingDate'] = errorStr[errorMapFields['MeetingDate']];
          }
          if (errorStr[errorMapFields['ExitDate']]) {
            allErrorFields['ExitDate'] = errorStr[errorMapFields['ExitDate']];
          }
        }
        if (item === 'arrival_date' || item === 'departure_date') {
          let arrivalDepartureError = getDateValidationError(tempReviewInfo['arrival_date'], tempReviewInfo['departure_date'], errorMapFields['arrival_date'], errorMapFields['departure_date']);
          if (arrivalDepartureError[errorMapFields['arrival_date']]) {
            allErrorFields['arrival_date'] = arrivalDepartureError[errorMapFields['arrival_date']];
          }
          if (arrivalDepartureError[errorMapFields['departure_date']]) {
            allErrorFields['departure_date'] = arrivalDepartureError[errorMapFields['departure_date']];
          }
        }
      }
    });
    console.log("allErrorFields edit review", allErrorFields)
    if (Object.keys(allErrorFields).length > 0) {
      setErrorFields(allErrorFields)
      return;
    }

    var newTempInfo = {}
    Object.keys(tempReviewInfo).map(key => {

      if(tempReviewInfo[key] == "Invalid Date"){
        newTempInfo[key] = null
      }else{
        newTempInfo[key] =tempReviewInfo[key] 
      }

    })

    post(`reviews/update_details?review_id=${id}`, newTempInfo)
      .then((res) => {
        if (res) {
          toast.success('Updated Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        }
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });
  };

  const handleChange = (e) => {
    if (e.target.name && e.target.value) {
      console.log(e.target.value, 'targteValue')
      if (e.target.type === "time") {
        if (e.target.value != "") {
          const value = e.target.value;
          const splitTime = value.split(":");
          setReviewInfo((prev) => {
            return {
              ...prev,
              [e.target.name]: dayjs(prev[e.target.name] ? prev[e.target.name] : dayjs(new Date()).format('YYYY-MM-DD'))
                .set("hour", splitTime[0])
                .set("minute", splitTime[1])
                .format("YYYY-MM-DDTHH:mm:ss.sssZ"),
            };
          });
        }

      } else {
        setReviewInfo((prev) => {
          return { ...prev, [e.target.name]: e.target.value };
        });
      }
    } else if (e.target.name) {
      setReviewInfo((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  const createTeamCompletion = (e) => {
    if (e) {
      e.preventDefault();

      const formData = new FormData(e.target);
      const formEntrries = Object.fromEntries(formData);

      post(`reviews/update_status`, { ...formEntrries, review_id: id })
        .then((res) => {
          if (res) {
            toast.success('Updated Successfully', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
          }
        })
        .catch((err) => {
          toast.error(err.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        });
    }
  }

  const createNewHotel = () => {
    navigate(
      `/reviews/${id}/hotel/new/edit`
    )
  }

  console.log(hotelInfoData, 'hotelInfoData')
  const accordians = [
    {
      label: <div>Review Info</div>,
      content: isEditEnabled ? (
        <ReviewInfoEdit reviewInfoData={reviewInfoData} errorFields={errorFields} />
      ) : (
        <ReviewInfoView reviewInfoData={reviewInfoData} />
      ),
    },
    {
      label: "Grantee Info",
      content: <GranteeInfo granteeInfo={granteeInfoData} />,
    },
    {
      label: <div className="flex gap-6 w-full items-center">
        <div>Hotel Info</div>
        {
          hotelInfoData && hotelInfoData.length <= 0 && <div className="ml-auto mb-1">
            <TertiaryButton onClick={createNewHotel} className={"px-3 py-1"} label={"Create Hotel"} />
          </div>
        }

      </div>,
      content: <HotelInfoTable hotelInfo={hotelInfoData} />,
    },
    {
      label: "Reviewers Info",
      content: <ReviewersInfoTable reviewerInfo={reviewerInfoData} />,
    },
  ];

  return (
    <>
      <div className="flex justify-between py-2">
        <div className="text-justify font-bold text-xl">Review Detail</div>
        <div className="ml-auto flex gap-6">
          <AccordianAction accordianAction={accordianAction} handleAccordian={(d) => setAccordianAction(d)} />
          <button
            type="button"
            onClick={() => setEdit(!isEditEnabled)}
            className="py-2 px-10 align-end inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-custom-blue text-custom-blue hover:text-white hover:bg-custom-blue"
          >
            {isEditEnabled ? "Cancel" : "Edit"}
          </button>
        </div>

      </div>
      <form onChange={handleChange} onSubmit={handleSubmit} id="detailsForm">
        <div className="bg-white p-2 shadow-sm rounded-md border border-[#E5E7EB]">
          <AccordianPrimary accordians={accordians} accordianAction={accordianAction} />
        </div>
        {isEditEnabled ? (
          <div className="flex flex-col gap-10">
            <div className="flex gap-2 mt-10">
              <SecondaryButton
                className={"py-3 px-10"}
                onClick={() => setEdit(false)}
                label={"Cancel"}
                type="button"
              />
              <PrimaryButton
                type="submit"
                className={"py-3 px-10"}
                label={"Update"}
              />{" "}
            </div>

          </div>
        ) : (
          <></>
        )}
      </form>
      {
        isEditEnabled ? <div className="mt-10">
          <form key="completionStatus" onSubmit={createTeamCompletion}>
            <div className="flex gap-6 text-left mb-20">
              <div className="w-[192px]">
                <Select
                  label="Team Completion"
                  name="completion_status"
                  id="completion_status"
                  options={statusLists}
                />
              </div>
              <div className="mt-auto">
                <TertiaryButton
                  type="submit"
                  label="Submit"
                  className={"px-3 py-2"}
                />
              </div>
            </div>
          </form>
        </div> : <></>
      }
    </>
  );
};

export default ViewEditDetailTab;
