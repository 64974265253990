import React from "react";
import Sidebar from "./Sidebar";
import PropTypes from "prop-types";

// eslint-disable-next-line react/prop-types
const Layout = ({ children }) => {
  return (
    <div className="flex w-full">
      <div className="lg:block w-[235px] h-screen fixed">
        <Sidebar />
      </div>

      <div className="w-full lg:pl-[235px] bg-bg-primary">
        <div className="px-8 pt-4">{children}</div>
      </div>
    </div>
  );
};

Layout.prototype = {
  children: PropTypes.node,
};

export default Layout;
