import React from "react";
import TextWithLabel from "../inputs/text";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";

export default function GranteesView({granteeSearchData}) {

  return (
    <div className="flex flex-col gap-4 justify-between py-4">
      <div className="flex flex-col gap-4 text-left">
        <div className="flex flex-col gap-8 text-left">
          <div className="flex gap-8">
            <div className="w-60">
              <TextWithLabel label="Grantee Id" value={granteeSearchData?.GranteeID} />
            </div>
            <div className="w-60 whitespace-nowrap">
              <TextWithLabel label="Label" value={granteeSearchData?.Name} />
            </div>
          </div>
          <div>
            <TextWithLabel label="Address" value={granteeSearchData?.Address} />
          </div>
          <div className="flex gap-8">
            <div className="w-60">
              <TextWithLabel label="City" value={granteeSearchData?.City} />
            </div>
            <div className="w-60">
              <TextWithLabel label="State" value={granteeSearchData?.State} />
            </div>
            <div className="w-60">
              <TextWithLabel label="Zip Code" value={granteeSearchData?.Zip} />
            </div>
          </div>
          <div className="flex gap-8">
            <div className="w-60">
              <TextWithLabel label="County" value={granteeSearchData?.County} />
            </div>
            <div className="w-60">
              <TextWithLabel label="Airport" value={granteeSearchData?.airport} />
            </div>
          </div>
          <div className="flex gap-8">
            <div className="w-60">
              <TextWithLabel label="Phone" value={formatPhoneNumber(granteeSearchData?.Phone)} />
            </div>
            <div className="w-60">
              <TextWithLabel label="Fax" value={formatPhoneNumber(granteeSearchData?.Fax)} />
            </div>
          </div>

          <div className="flex gap-8">
            <div className="w-60">
              <TextWithLabel label="Contact" value={granteeSearchData?.Contact} />
            </div>
            <div className="w-60">
              <TextWithLabel label="Director" value={granteeSearchData?.Director} />
            </div>
          </div>
          <div className="w-60">
            <TextWithLabel label="Has Delegate" value={granteeSearchData?.hasdelegate} />
          </div>

          <div className="w-60">
            <TextWithLabel label="Delegate of" value={granteeSearchData?.isdelegateof} />
          </div>

          <div className="w-60">
            <TextWithLabel label="Email" value={granteeSearchData?.email} />
          </div>
          <div className="w-60">
            <TextWithLabel label="Region" value={granteeSearchData?.Region} />
          </div>
        </div>
      </div>
    </div>
  );
}
