import React from "react";
import PrimaryButton from "../buttons/primary";

export default function AccordianAction({ accordianAction, handleAccordian }) {
  return (
    <div className="">
      {accordianAction == "expandAll" ? (
        <PrimaryButton
          className={"px-2 py-2"}
          type="button"
          onClick={() => handleAccordian("collapseAll")}
          label="Collapse"
        ></PrimaryButton>
      ) : accordianAction == "collapseAll" ? (
        <PrimaryButton
          className={"px-2 py-2"}
          type="button"
          onClick={() => handleAccordian("expandAll")}
          label="Expand"
        ></PrimaryButton>
      ) : (
        <></>
      )}
    </div>
  );
}
