import React from "react";
import Select from "../inputs/select";
import InputDate from "../inputs/date";
import PrimaryButton from "../buttons/primary";
import DataTable from "react-data-table-component";

export default function OutstandingReviewerPayments() {
  const columns = [
    {
      name: <div className="uppercase text-md text-indigo-600">VENDOR ID</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEWER</div>,
      selector: (row) => row.training,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW ID</div>,
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW DATE</div>,
      selector: (row) => row.dates,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase text-md text-gray-600">TER RECEIVED DATE</div>
      ),
      selector: (row) => row.dates,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">DUE DATE</div>,
      selector: (row) => row.dates,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">CHECK DATE</div>,
      selector: (row) => row.dates,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase text-md text-gray-600">DIRECT DEPOSIT</div>
      ),
      selector: (row) => row.dates,
      sortable: true,
    },
  ];
  const data = [
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
    },
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
    },
  ];
  return (
    <form>
      <div className="flex flex-col gap-6">
        <div>
          <div className="w-[200px]">
            <Select label="Fiscal Year" options={[]} />
          </div>
        </div>
        <div className="flex gap-8">
          <div className="w-[200px]">
            <InputDate label="From Date" />
          </div>
          <div className="w-[200px]">
            <InputDate label="To Date" />
          </div>
        </div>
        <div className="w-[170px]">
          <PrimaryButton label="See Data" className={"px-3 py-2"} />
        </div>
        <div className="flex flex-col gap-6 text-left">
          <h6 className="text-[20px] font-[500]">Outstanding Reviewers</h6>
          <DataTable columns={columns} data={data} />
        </div>
      </div>
    </form>
  );
}
