import React from "react";
import Search from "../../inputs/search";
import NewReviews from "./new-reviews";
import Changes from "./changes";

export default function ProjectInbox() {
  return (
    <div className="flex flex-col text-left gap-8">
      <div className="flex flex-col gap-6 mt-6">
        <h6 className="font-semibold text-[20px]">Project Inbox</h6>
        <div className="w-[400px]">
          <Search searchLabel="Search" />
        </div>
      </div>
      <NewReviews />
      <Changes />

    </div>
  );
}
