import React from 'react'
import TertiaryButton from '../../../../buttons/tertiary'

export default function MiscellaneousExpense() {
  return (
    <div className="p-7 flex flex-col gap-10 text-left">
        <TertiaryButton label={"Add Miscellaneous Expense"} className={"px-3 py-2 w-[259px]"}/>
    </div>
  )
}
