import React, { useState } from "react";
import Select from "../../components/inputs/select";
import TertiaryButton from "../../components/buttons/tertiary";
import TextWithLabel from "../../components/inputs/text";
import Input from "../../components/inputs/input";

import PrimaryButton from "../../components/buttons/primary";
import SecondaryButton from "../../components/buttons/secondary";
import RadioGroup from "../../components/inputs/radio-group";
import CashAdvanceStatus from "../../components/reviewer-status/cash-advance-status";
import ForDanyaUse from "../../components/reviewer-status/for-danya-use";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from '../../components/typhography/title-primary'


export default function ReviewersStatus() {
  const [historyEnabled, setHistoryEnabled] = useState(false);

  return (
    <div>
      <div>
      <PrimaryBreadCrumbs label="Reviewers" path="/reviews" />

        <div className="flex justify-between">
        <TitlePrimary title={"Change Reviewer Status"} />
        </div>
        <div className="mt-10">
          <div className="flex flex-col gap-10 text-left">
            <div className="flex flex-col gap-10 text-left">
              <div className="flex gap-10">
                <div className="w-[400px]">
                  <Select label="Select Reviewer" />
                </div>
                <div className="ml-auto mt-auto">
                  <TertiaryButton
                    label={"View Change History"}
                    className={"w-[160px] py-2 text-center text-sm"}
                    onClick={() => setHistoryEnabled(true)}
                  />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-[200px]">
                  <TextWithLabel label="Traveler ID" value="XXX-XX-8150" />
                </div>
                <div className="w-[200px]">
                  <TextWithLabel label="Last Prism" value="1/6/2006" />
                </div>
                <div className="w-[200px]">
                  <TextWithLabel label="Last EHS" value="-" />
                </div>
                <div className="w-[200px]">
                  <TextWithLabel label="Last Outcomes" value="-" />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-[200px]">
                  <TextWithLabel label="Name" value="Paul Davila" />
                </div>
                <div className="w-[400px]">
                  <TextWithLabel
                    label="Address"
                    value="2031 Marlene Ave, Redding, CA 96002"
                  />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-[200px]">
                  <TextWithLabel label="Phone" value="(405) 521-7208" />
                </div>
                <div className="w-[400px]">
                  <TextWithLabel label="Email" value="pdav68@hotmail.com" />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-[400px]">
                  <TextWithLabel
                    label="Grantee"
                    value="Kids Are First, Inc. / 01234F2C"
                  />
                </div>
                <div className="w-[400px]">
                  <TextWithLabel
                    label="Review Dates"
                    value="7/26/2010 - 7/29/2010"
                  />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-full">
                  <TextWithLabel
                    label="TER Notes"
                    value="Ticket change was approved by Cynthia on 7/30/10. Reviewer requested flight change to fly to Redding, CA instead of his home airport in Oklahoma. Reviewer is aware they are responsible for their own flight home from Redding, CA to Oklahoma."
                  />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-[450px]">
                  <TextWithLabel label="Email Notifications" value="" />
                  <TextWithLabel
                    label=""
                    value="Confirmation: Jlynn-Farrar@Danya.com 6/14/2010 8:48:35 PM"
                  />
                  <TextWithLabel
                    label=""
                    value="Cancellation: Blue-4chj@bellsouth.net 8/11/2010 4:32:05 PM"
                  />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-[200px]">
                  <TextWithLabel
                    label="Notified TDMG"
                    value="6/14/2010 8:49:14 PM"
                  />
                </div>
                <div className="w-[200px]">
                  <TextWithLabel
                    label="Submitted"
                    value="6/16/2010 7:31:20 AM"
                  />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-[200px]">
                  <Select label="Change Type" options={[]} />
                </div>
                <div className="w-[400px]">
                  <Select label="Sub Type" options={[]} />
                </div>
              </div>
              <div>
                <div className="w-full">
                  <Input type="textArea" label="Description" />
                </div>
              </div>
              <div>
                <div className="flex gap-10">
                  <SecondaryButton className={"py-3 px-10"} label={"Cancel"} />
                  <PrimaryButton
                    type="submit"
                    className={"py-3 px-10"}
                    label={"Update"}
                  />{" "}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-10 text-left">
              <h6 className="text-[20px] font-[500]">For Cancelled Reviews</h6>
              <div className="flex gap-10">
                <div className="w-[200]">
                  <RadioGroup
                    label="Ticket Owed to Danya"
                    name="ticket"
                    id="ticket"
                    groups={[
                      {
                        id: "Yes",
                        label: "Yes",
                        name: "hasdelegate",
                        value:"Yes"
                      },
                      {
                        id: "No",
                        label: "No",
                        name: "hasdelegate",
                        value:"No"
                      },
                    ]}
                  />
                </div>
                <div className="w-[200]">
                  <RadioGroup
                    label="E-Ticket"
                    name="ticket"
                    id="ticket"
                    groups={[
                      {
                        id: "Yes",
                        label: "Yes",
                        name: "hasdelegate",
                      },
                      {
                        id: "No",
                        label: "No",
                        name: "hasdelegate",
                      },
                    ]}
                  />
                </div>
              </div>
              <div>
                <TertiaryButton label="Update" className={"p-2 text-xs"} />
              </div>

              <CashAdvanceStatus />
              <ForDanyaUse />
            </div>
          </div>
        </div>
      </div>
      {historyEnabled ? (
        <div className="fixed top-0 bottom-0 right-0">
          <div className="bg-white shadow-lg w-[496px] h-screen p-10 text-left ">
            <div className="w-full flex">
              <div className="ml-auto">
                <Close
                  className="cursor-pointer"
                  onClick={() => setHistoryEnabled(false)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-5 py-10">
              <h6 className="text-[18px] font-[500] text-[#030712]">
                Change History
              </h6>
              <div className="bg-[#F1F5F9] p-4 flex flex-col gap-3">
                <div>
                  <div className="w-[196px]">
                    <TextWithLabel label="Date" value="7/28/2010 7:47:32 PM" />
                  </div>
                </div>
                <div className="flex gap-8">
                  <div className="w-[196px]">
                    <TextWithLabel label="Change Type" value="Change" />
                  </div>
                  <div className="w-[196px]">
                    <TextWithLabel
                      label="Sub Type"
                      value="Airline ticket changed"
                    />
                  </div>
                </div>
                <div>
                  <div className="w-full">
                    <TextWithLabel
                      label="Description"
                      value="Reviewer return flight to California and not home airport. Review required to provide own transportation. Flight changed due to family emergency. Exception request approved 7/29 via CNorthington."
                    />
                  </div>
                </div>
              </div>
              <div className="bg-[#F1F5F9] p-4 flex flex-col gap-3">
                <div>
                  <div className="w-[196px]">
                    <TextWithLabel label="Date" value="7/28/2010 7:47:32 PM" />
                  </div>
                </div>
                <div className="flex gap-8">
                  <div className="w-[196px]">
                    <TextWithLabel label="Change Type" value="Change" />
                  </div>
                  <div className="w-[196px]">
                    <TextWithLabel
                      label="Sub Type"
                      value="Airline ticket changed"
                    />
                  </div>
                </div>
                <div>
                  <div className="w-full">
                    <TextWithLabel
                      label="Description"
                      value="Review dates changed."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
