import React from "react";
import PrimaryButton from "../buttons/primary";
import DataTable from "react-data-table-component";

export default function ReviewerHolds() {
  const columns = [
    {
      name: <div className="uppercase text-md text-indigo-600">HOLD TYPE</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEWER</div>,
      selector: (row) => row.training,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">GRANTEE</div>,
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW DATE</div>,
      selector: (row) => row.dates,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">15 DAYS</div>,
      selector: (row) => row.dates,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">45 DAYS</div>,
      selector: (row) => row.dates,
      sortable: true,
    },
  ];
  const data = [
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
    },
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
    },
  ];
  return (
    <form>
      <PrimaryButton label="See Data" className={"px-3 py-2"} />
      <div className="flex flex-col gap-6 text-left mt-10">
        <h6 className="text-[20px] font-[500]">Hold Report</h6>
        <DataTable columns={columns} data={data} />
      </div>
    </form>
  );
}
