import React, {useState} from "react";
import Select from "../../../inputs/select";
import AccordianPrimary from "../../../accordian/primary";
import TravelerInfo from "./tabs/traveler-info";
import TripInfo from "./tabs/trip-info";
import Invoice from "./tabs/invoice";
import Honoraria from "./tabs/honoraria";
import HotelExpenses from "./tabs/hotel-expenses";
import CarExpenses from "./tabs/car-expenses";
import MiscellaneousExpense from "./tabs/miscellaneous-expense";
import CashAdvanceDetails from "./tabs/cash-advance";
import TERDetails from "./tabs/ter-details";
import TextWithLabel from "../../../inputs/text";
import PrimaryButton from "../../../buttons/primary";
import AccordianAction from "../../../accordian/accordian-action";

export default function ProcessTER() {
  const [accordianAction, setAccordianAction] = useState("expandAll");
  const accordians = [
    {
      label: "Traveler Info",
      content: (
        <div>
          <TravelerInfo />
        </div>
      ),
    },
    {
      label: "Trip Info",
      content: (
        <div>
          <TripInfo />
        </div>
      ),
    },
    {
      label: "Invoice",
      content: (
        <div>
          <Invoice />
        </div>
      ),
    },
    {
      label: "Honoraria & MIE Expenses 4/18/2016 - 04/23/2016",
      content: (
        <div>
          <Honoraria />
        </div>
      ),
    },
    {
      label: "Hotel Expenses",
      content: (
        <div>
          <HotelExpenses />
        </div>
      ),
    },
    {
      label: "Car Expenses",
      content: (
        <div>
          <CarExpenses />
        </div>
      ),
    },
    {
      label: "Miscellaneous Expense",
      content: (
        <div>
          <MiscellaneousExpense />
        </div>
      ),
    },
    {
      label: "Cash Advance Details",
      content: (
        <div>
          <CashAdvanceDetails />
        </div>
      ),
    },
    {
      label: "TER Details",
      content: (
        <div>
          <TERDetails />
        </div>
      ),
    },
  ];
  return (
    <div className="py-10 text-left">
      <div className="mb-10 flex">
        <h5 className="font-semibold text-xl">Process TER</h5>
        <div className="ml-auto">
            <AccordianAction accordianAction={accordianAction} handleAccordian={(d) => setAccordianAction(d)} />
          </div>
      </div>
      <div className="flex flex-col gap-10">
        <div className="w-[548px]">
          <Select label="Reviewer" name="" id="" options={[]} />
        </div>
        <div className="border rounded bg-white border-[#E5E7EB] p-10">
          <AccordianPrimary accordians={accordians} accordianAction={accordianAction}/>
        </div>
        <div className="h-[1px] w-full bg-gray-300"></div>
        <div className="flex flex-col gap-10">
          <div className="flex w-full">
            <div>
              <h6 className="font-semibold text-[14px]">Totals</h6>
            </div>
            <div className="ml-auto flex gap-10">
              <div className="w-[200px]">
                <TextWithLabel label="ENCUMBRANCE" value="$2,337.90" />
              </div>
              <div className="w-[200px]">
                <TextWithLabel label="CASH ADVANCE" value="$0.00" />
              </div>
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[400px]">
              <Select label="Process Status" options={[]} />
            </div>
            <div className="mt-auto">
              <PrimaryButton label="Update" className={"px-4 py-3"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
