/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

const InputDate = ({ label, disabled, className, required, isErrorOccurred, ...props }) => {

  console.log(props, 'propsssss')

  return (
    <div className="flex flex-col gap-2">
      {label ? (
        <label className="text-text-secondary font-[400] text-[14px]">
          {label}
          {required && <span className="text-red-600"> *</span>}
        </label>
      ) : (
        <></>
      )}
      <input type={"date"} max={"9999-12-31"} disabled={disabled} {...props} className={` ${disabled ? 'bg-gray-200' : 'bg-white'} border px-2 border-input-border  h-[46px] rounded-md shadow-sm ${isErrorOccurred ? 'border-red-500' : ''} ${className ? className : ''}`} />
      {isErrorOccurred && <p className="text-red-500 text-xs italic">{isErrorOccurred}</p>}
    </div>
  );
};

InputDate.prototype = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default InputDate;
