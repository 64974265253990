import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TextWithLabel from "../../components/inputs/text";
import Select from "../../components/inputs/select";
import PrimaryButton from "../../components/buttons/primary";

import HotelDetailsEdit from "../../components/reviews/hotel-view/edit";
import HotelDetailsView from "../../components/reviews/hotel-view/view";
import SecondaryButton from "../../components/buttons/secondary";
import Input from "../../components/inputs/input";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from "../../components/typhography/title-primary";
import { get, post } from "../../services";
import CheckBoxGroup from "../../components/inputs/checkbox-group";
import TertiaryButton from "../../components/buttons/tertiary";
import dayjs from "dayjs";
import {  toast, Bounce } from 'react-toastify';


export default function ViewHotelDetails() {
  const { id, hotelID, mode } = useParams();
  const navigate = useNavigate();
  console.log(id, hotelID);
 

  const [loading, setLoading] = useState(true);
  

  //const [hotelDeta]

  const [hotelDetails, setHotelDetails] = useState({});
  const [viewHotel, setViewHotel] = useState({});
  const [isEditEnabled, setEditEnabled] = useState(
    mode && mode === "edit" ? true : false
  );
  const [isAddNewEnabled, setAddNewEnabled] = useState(false);
  const [hotelsAdded, setHotelsAdded] = useState([]);
  
  const [hotelSearchedList, setHotelSearchedList] = useState([]);

  const [selectedHotelsAdded, setSelectHotelAdded] = useState(null);
  const [selectedHotelSearch, setSelectHotelSearch] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [isSearchHotelSelected, setSelectSearchHotel] = useState(false);




  useEffect(() => {
    if (hotelID && hotelID == "new") {
      setEditEnabled(true);
      setHotelDetails({});
      //setHotels([]);
      setAddNewEnabled(true);
      setSearchData(() => {
        return {};
      });
    }
  }, []);

  useEffect(() => {
    if (hotelID != "new") {
      get(`reviews/get_assigned_hotels?ReviewId=${id}`)
        .then((res) => {
          console.log(res, "res");
          if (res && res.length > 0) {
            // setHotels(
            //   res.map((s) => {
            //     var temp = {};
            //     temp["label"] = s.HotelDesc;
            //     temp["value"] = s.trip_hotel_uid;
            //     return temp;
            //   })
            // );
            // setAddedHotels(
            //   res.map((s) => {
            //     var temp = {};
            //     temp["label"] = s.HotelDesc;
            //     temp["value"] = s.trip_hotel_uid;
            //     return temp;
            //   })
            // );
            //setAddedHotelSelect(res[0]?.trip_hotel_uid);
            //getHotelDetails(res[0]?.trip_hotel_uid);


            setHotelsAdded(
              res.map((s) => {
                var temp = {};
                temp["label"] = s.HotelDesc;
                temp["value"] = s.trip_hotel_uid;
                return {...temp, ...s};
              })
            )
            const filterCurrentHotel = res.filter(d => d.hotel_uid == hotelID);

           if(filterCurrentHotel && filterCurrentHotel.length > 0){
            setSelectHotelAdded(filterCurrentHotel[0].trip_hotel_uid)
            getHotels(filterCurrentHotel[0])
           }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });









      // get(`reviews/hotel_detail?hotel_uid=${hotelID}`)
      //   .then((res) => {
      //     console.log(res, "res");
      //     if (res && res.length > 0) {
      //       setHotels(
      //         res.map((s) => {
      //           var temp = {};

      //           var tempString = "";

      //           if (s.Phone) {
      //             tempString += `${s.Phone},`;
      //           }
      //           if (s.Name) {
      //             tempString += `${s.Name},`;
      //           }
      //           if (s.Address) {
      //             tempString += `${s.Address}`;
      //           }

      //           temp["label"] = tempString;
      //           temp["value"] = s.hotel_uid;
      //           return temp;
      //         })
      //       );

      //       SetHotelOriginal(res);

      //       setAddedHotelSelect(res[0]?.hotel_uid);

      //       setTimeout(() => {
      //         setHotelDetails((prev) => {
      //           return { ...prev, ...res[0] };
      //         });
      //       }, 100);
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false);
      //     console.error(err);
      //   });
    } else {
      setLoading(false);
    }
  }, []);

  const getHotels = async(currentHotel) => {
    if(currentHotel && currentHotel.hotel_uid){
      await get(`reviews/hotel_detail?hotel_uid=${currentHotel.hotel_uid}`)
      .then((res) => {
        console.log(res, "res");
        if (res && res.length > 0) {
            setHotelDetails(res[0])
        }
      })
      .catch((err) => {
        //setLoading(false);
        console.error(err);
      });
    }

    if(currentHotel && currentHotel.trip_hotel_uid){
      await get(`reviews/view_hotel?trip_hotel_uid=${currentHotel.trip_hotel_uid}`)
      .then((res) => {
        if (res && res.length > 0) {
          setViewHotel(res[0])
        }
        //setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        //setLoading(false);
      });
    }

    setLoading(false)
    
  }

  const changeHotelSearch = (e) => {
    //setSearch(e.target.value);
    console.log(e.target.value);

    if (e && e.target.value) {
      
      setSelectHotelSearch(e.target.value)
    }
  };

  const changeHotelAdded = (e) => {
    //setSearch(e.target.value);
    console.log(e.target.value, hotelsAdded);
    setLoading(true)
    if (e && e.target.value) {
      // setHotelSelect(false);
      // getHotelDetails(e.target.value);
      const filterCurrentHotel = hotelsAdded.filter(d => d.value == e.target.value);
      console.log(filterCurrentHotel, 'filterCurrentHotel')

      if(filterCurrentHotel && filterCurrentHotel.length > 0){
       setSelectHotelAdded(filterCurrentHotel[0].trip_hotel_uid)
       getHotels(filterCurrentHotel[0])
      }

    }
  };

  // const getHotelDetails = (trip_hotel_uid) => {
  //   get(`reviews/view_hotel?trip_hotel_uid=${trip_hotel_uid}`)
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         setHotelDetails(res[0]);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       setLoading(false);
  //     });
  // };

  const handleSearchChange = (e) => {
    if (e.target.name && e.target.value) {
      if (e.target.type === "time") {
        const value = e.target.value;
        const splitTime = value.split(":");
        console.log(splitTime, "splitTime");
        setSearchData((prev) => {
          return {
            ...prev,
            [e.target.name]: dayjs(prev[e.target.name])
              .set("hour", splitTime[0])
              .set("minute", splitTime[1])
              .format("YYYY-MM-DDTHH:mm:ss.sssZ"),
          };
        });
      } else {
        setSearchData((prev) => {
          return { ...prev, [e.target.name]: e.target.value };
        });
      }
    } else if (e.target.name) {
      setSearchData((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
    }
  };

  const onSearch = (e) => {
    if (e) {
      e.preventDefault();

      //const formData = new FormData(e.target);
      const formEntrries = searchData;
      console.log(id, "idddddd");

      var query = `review_id=${id}`;

      if (formEntrries.city) {
        query += `&city=${formEntrries.city}`;
      }
      if (formEntrries.zip) {
        query += `&zip=${formEntrries.zip}`;
      }

      if (formEntrries.phone) {
        query += `&phone=${formEntrries.phone}`;
      }

      // console.log(query, "query");

      get(`reviews/search_hotels?${query}`)
        .then((res) => {
          if (res && res.length > 0) {
            setHotelSearchedList(
              res.map((s) => {
                var temp = {};
                temp["label"] = s.HotelDescription;
                temp["value"] = s.hotel_uid;
                return temp;
              })
            );

            setSelectHotelSearch(res[0].hotel_uid)

            // SetHotelOriginal(res);
            // setAddedHotelSelect(res[0]?.hotel_uid);
            // getHotelDetails(res[0]?.hotel_uid);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const addNew = () => {
    setEditEnabled(false);
    setHotelDetails(() => {
      return {};
    });
    setViewHotel(() => {
      return {};
    });
    //setHotels([]);
    setAddNewEnabled(true);
    setSearchData(() => {
      return {};
    });
    setSelectSearchHotel(false)
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const URI_CREATE_HOTEL = isAddNewEnabled
      ? "reviews/create_hotel"
      : "reviews/update_hotel";
    const URI_CREATE_HOTEL_REVIEW =
      isAddNewEnabled || isSearchHotelSelected
        ? "reviews/create_hotel_review"
        : "reviews/update_hotel_info";

    var hotelReq = {
      Name: hotelDetails?.Name,
      Address: hotelDetails?.Address,
      address2: hotelDetails?.Address,
      City: hotelDetails?.City,
      State: hotelDetails?.State,
      County: hotelDetails?.County,
      Zip: hotelDetails?.Zip,
      Phone: hotelDetails?.Phone,
      fax: hotelDetails?.fax,
      UserId: hotelDetails?.UserId,
    };

    if (!isAddNewEnabled && !isSearchHotelSelected) {
      const filterCurrentHotel = hotelsAdded.filter(d => d.value == selectedHotelsAdded);
      hotelReq["Hotel_uid"] = filterCurrentHotel[0].hotel_uid;
    }

    var reviewRequest = {...viewHotel};

    if(isAddNewEnabled || isSearchHotelSelected){
      reviewRequest["trip_id"] = id
    }else{
      const filterCurrentHotel = hotelsAdded.filter(d => d.value == selectedHotelsAdded);
      reviewRequest["trip_hotel_uid"] = filterCurrentHotel[0].trip_hotel_uid
      if(reviewRequest && reviewRequest.hotel_uid){
        delete reviewRequest.hotel_uid;
      }
      if(reviewRequest && reviewRequest.trip_uid){
        delete reviewRequest.trip_uid;
      }
    }

    console.log(navigate)

    if (isAddNewEnabled) {
      var tempData = {
        name: hotelDetails?.Name,
        address: hotelDetails?.Address,
        city: hotelDetails?.City,
        zip: hotelDetails?.Zip,
      };
      post(`reviews/check_duplicate_hotel`, tempData)
        .then((res) => {
          console.log(res, 'ress')
          if(res === true){
            toast.error('Hotel already exists. Try different one', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
              });
          }else if (res && !res.data) {
            post(URI_CREATE_HOTEL, hotelReq)
              .then((res) => {
                console.log(res);
                if(res && res.length > 0){
                  reviewRequest["hotel_uid"] = res[0].Hotel_uid
                  post(URI_CREATE_HOTEL_REVIEW, reviewRequest)
                  .then((res) => {
                    console.log(res);
                    if (res) {
                      navigate(`/reviews/details/${id}`);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }else if(isSearchHotelSelected){
      reviewRequest["hotel_uid"] = selectedHotelSearch
      post(URI_CREATE_HOTEL_REVIEW, reviewRequest)
      .then((res) => {
        console.log(res);
        if (res) {
         navigate(`/reviews/details/${id}`);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    }else if(isEditEnabled){
      post(URI_CREATE_HOTEL, hotelReq)
      .then((res) => {
        console.log(res);
        if(res){
          //reviewRequest["hotel_uid"] = res[0].Hotel_uid
          post(URI_CREATE_HOTEL_REVIEW, reviewRequest)
          .then((res) => {
            console.log(res);
            if (res) {
              navigate(`/reviews/details/${id}`);
            }
          })
          .catch((err) => {
            console.error(err);
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
    }
  };

  const handleChange = (e) => {
    console.log(e.target.value, "e.target", e.target.type);
    var isViewSection = false;

    if(e.target && e.target.id){
      const splitID= e.target.id.split("-")
      if(splitID && splitID.length > 0 && splitID[0] == "view"){
        isViewSection = true
      }
    }

    if (e.target.name && e.target.value) {
      if (e.target.type === "time") {
        const value = e.target.value;
        const splitTime = value.split(":");
        console.log(splitTime, "splitTime");
        if(isViewSection){
          setViewHotel((prev) => {
            return {
              ...prev,
              [e.target.name]: dayjs(prev[e.target.name])
                .set("hour", splitTime[0])
                .set("minute", splitTime[1])
                .format("YYYY-MM-DDTHH:mm:ss.sssZ"),
            };
          });
        }else{
          setHotelDetails((prev) => {
            return {
              ...prev,
              [e.target.name]: dayjs(prev[e.target.name])
                .set("hour", splitTime[0])
                .set("minute", splitTime[1])
                .format("YYYY-MM-DDTHH:mm:ss.sssZ"),
            };
          });
        }
    
      } else {
        if(isViewSection){
          setViewHotel((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
          });
        }else{
          setHotelDetails((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
          });
        }
       
      }
    } else if (e.target.name) {
      if(isViewSection){

        setViewHotel((prev) => {
          return { ...prev, [e.target.name]: "" };
        });
      }else{
        
      setHotelDetails((prev) => {
        return { ...prev, [e.target.name]: "" };
      });
      }
    }
  };

  const selectHotel = () => {
    if (selectedHotelSearch) {
      setAddNewEnabled(false);
      setEditEnabled(false);
  
      setTimeout(() => {
        //getHotelDetails(selectedHotelsAdded)

        //getHotelDetails(selectedHotelsAdded);
        get(`reviews/hotel_detail?hotel_uid=${selectedHotelSearch}`)
          .then((res) => {
            console.log(res, "res");
            if (res && res.length > 0) {
              // setHotelSelect(true);
              // setHotelDetails(res[0]);
              setHotelDetails(res[0])
              setViewHotel({})
              setSelectSearchHotel(true)
            }
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
      }, 100);
    }
  };

  if (loading) {
    return <div>...loading</div>;
  }

  const cistyStateZip =
    (hotelDetails?.City || hotelDetails?.State || hotelDetails?.Zip) &&
    `${hotelDetails?.City}, ${hotelDetails?.State}, ${hotelDetails?.Zip}`;

  console.log(hotelDetails, "hotelseleted", "searchss");

  return (
    <div className="flex flex-col gap-5 w-full text-left">
      <PrimaryBreadCrumbs
        label="Review Details"
        path={`/reviews/details/${id}`}
      />

      <div className="text-left">
        <TitlePrimary
          title={` Review ID: ${id}: ${isAddNewEnabled ? "Add" : isEditEnabled ? "Edit" : "View"} Hotel Details`}
        />
      </div>
      <div className="flex gap-6">
        <div className="w-[300px]">
          {/* <h6 className="mb-5 font-semibold text-[16px]">Select a Hotel</h6> */}
          <Select
            label="Hotel"
            options={hotelSearchedList}
            selected={selectedHotelSearch}
            onChange={changeHotelSearch}
          />
        </div>
        <div className="mt-auto">
          <TertiaryButton
            onClick={selectHotel}
            label="Select This Hotel"
            className="px-4 py-2"
          />
        </div>
      </div>

      <form onSubmit={onSearch} onChange={handleSearchChange}>
        {/* <h6 className="mb-5 font-semibold text-[16px]">Hotel Search</h6> */}
        <div className="flex gap-8">
          <div className="w-[400px]">
            <Input
              label="city"
              name="city"
              id="city"
              autocomplete="off"
              value={searchData?.city ? searchData?.city : ""}
            />
          </div>
          <div className="w-[192px]">
            <Input
              label="zip"
              name="zip"
              id="zip"
              autocomplete="off"
              value={searchData?.zip ? searchData?.zip : ""}
            />
          </div>
          <div className="w-[192px]">
            <Input
              label="phone"
              name="phone"
              id="phone"
              autocomplete="off"
              value={searchData?.phone ? searchData?.phone : ""}
            />
          </div>
          <div className="mt-auto">
            <PrimaryButton label="Search" className={"py-2 px-3"} />
          </div>
        </div>
      </form>

      <div className="flex gap-6">
        <div className="w-[300px]">
          {/* <h6 className="mb-5 font-semibold text-[16px]">Select a Hotel</h6> */}
          <Select
            label="Hotel Added"
            options={hotelsAdded}
            onChange={changeHotelAdded}
            selected={selectedHotelsAdded}
          />
        </div>
        <div className="mt-auto flex gap-6 items-center">
          {!isSearchHotelSelected ? (
            !isAddNewEnabled ? (
              <TertiaryButton
                onClick={() => setEditEnabled(true)}
                label="Edit"
                className="px-4 py-2"
              />
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {isEditEnabled ? (
            <></>
          ) : (
            <TertiaryButton
              onClick={addNew}
              label="Add New"
              className="px-4 py-2"
            />
          )}
        </div>
      </div>

      <form
        className="flex flex-col gap-5"
        onChange={handleChange}
        onSubmit={handleSubmit}
      >
        <div className="h-[1px] bg-gray-300 w-full my-4"></div>

        <div className="w-full my-5">
          <h6 className="mb-5 font-semibold text-[20px]">Hotel Info</h6>
          {/* {isEditEnabled || isSearchHotelSelected ? ( */}
          <div className="my-5">
            <CheckBoxGroup
              alignmentType="col"
              groups={[
                {
                  label: "Do Not Show In Team Summary",
                  value: "Do Not Show In Team Summary",
                  checked:
                    hotelDetails &&
                    hotelDetails?.Not_in_TS &&
                    hotelDetails?.Not_in_TS == "Yes",
                },
                {
                  label: "Travel Order Letter",
                  value: "Travel Order Letter",
                  checked:
                    hotelDetails &&
                    hotelDetails?.Travel_ord_letter &&
                    hotelDetails?.Travel_ord_letter == "Yes",
                },
              ]}
            />
          </div>
          {/* ) : (
              <></>
            )} */}

          <div className="flex flex-col items-start justify-start gap-8 w-full">
            <div className="flex gap-8 w-full">
              <div className="w-[300px] text-left">
                {isEditEnabled || isAddNewEnabled ? (
                  <Input
                    label="Hotel Name"
                    name="Name"
                    id="Name"
                    value={hotelDetails?.Name}
                  />
                ) : (
                  <TextWithLabel
                    label="Hotel Name"
                    value={hotelDetails?.Name}
                  />
                )}
              </div>

              <div className="w-[300px] text-left">
                {isEditEnabled || isAddNewEnabled ? (
                  <Input
                    label="Address"
                    name="Address"
                    id="Address"
                    value={hotelDetails?.Address}
                  />
                ) : (
                  <TextWithLabel
                    label="Address"
                    value={hotelDetails?.Address}
                  />
                )}
              </div>
            </div>
            <div className="flex gap-8 w-full">
              <div className="w-[600px] text-left">
                {isEditEnabled || isAddNewEnabled ? (
                  <div className="flex gap-6">
                    <Input
                      label="City"
                      name="City"
                      id="City"
                      value={hotelDetails?.City}
                    />
                    <Input
                      label="State"
                      name="State"
                      id="State"
                      value={hotelDetails?.State}
                      maxLength="2"
                      size="sm"
                    />
                    <Input
                      label="Zip"
                      name="Zip"
                      id="Zip"
                      maxLength="10"
                      value={hotelDetails?.Zip}
                    />
                  </div>
                ) : (
                  <TextWithLabel
                    label="City/State/Zip"
                    value={cistyStateZip ? cistyStateZip : ""}
                  />
                )}
              </div>

              <div className="w-[300px] text-left">
                {isEditEnabled || isAddNewEnabled ? (
                  <Input
                    label="County"
                    name="County"
                    id="County"
                    value={hotelDetails?.County}
                  />
                ) : (
                  <TextWithLabel label="County" value={hotelDetails?.County} />
                )}
              </div>
            </div>
            <div className="flex gap-8 w-full">
              <div className="w-[200px] text-left">
                {isEditEnabled || isAddNewEnabled ? (
                  <Input
                    label="Phone"
                    name="Phone"
                    id="Phone"
                    maxLength="10"
                    value={hotelDetails?.Phone}
                  />
                ) : (
                  <TextWithLabel label="Phone" value={hotelDetails?.Phone} />
                )}
              </div>

              <div className="w-[200px] text-left">
                {isEditEnabled || isAddNewEnabled ? (
                  <Input
                    label="Fax"
                    name="fax"
                    id="fax"
                    maxLength="10"
                    value={hotelDetails?.fax}
                  />
                ) : (
                  <TextWithLabel label="Fax" value={hotelDetails?.fax} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="h-[1px] bg-gray-300 w-full my-4"></div>

        <div className="w-full my-5">
          <div className="flex items-center">
            <h6 className="mb-5 font-semibold text-[16px]">Review Info</h6>
            {/* <div className="ml-auto">
                <PrimaryButton
                  onClick={() => setEditEnabled(!isEditEnabled)}
                  label={`${isEditEnabled || isAddNewEnabled ? "Cancel" : "Edit"}`}
                  className={"px-3 p-1"}
                />
              </div> */}
          </div>
          {
            isSearchHotelSelected || isEditEnabled || isAddNewEnabled ? <HotelDetailsEdit hotelDetails={viewHotel} /> : <HotelDetailsView hotelDetails={viewHotel} />
          }
          {/* {isSearchHotelSelected ? (
            <HotelDetailsEdit hotelDetails={viewHotel} />
          ) : isEditEnabled || isAddNewEnabled ? (
            <HotelDetailsEdit hotelDetails={viewHotel} />
          ) : (
            <HotelDetailsView hotelDetails={viewHotel} />
          )} */}
          {isEditEnabled || isSearchHotelSelected || isAddNewEnabled ? (
            <div className="flex gap-4 mt-10">
              <SecondaryButton
                onClick={() => window.location.reload()}
                label="Cancel"
                className={"px-4 py-2"}
                type="button"
              />
              <PrimaryButton type="submit" label="Submit" className={"px-4 py-2"} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </form>
    </div>
  );
}
