import React, {useState} from "react";
import Input from "../../components/inputs/input";
import TertiaryButton from "../../components/buttons/tertiary";
import PrimaryButton from "../../components/buttons/primary";
import { useNavigate } from "react-router-dom";
import TitlePrimary from "../../components/typhography/title-primary";
import { get } from "../../services";
import SearchResults from "../../components/ftl/search-results";

export default function RTL() {

    const navigate = useNavigate()

    const [formData, setFormData] = useState({})
    const [data, setData] = useState([])


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData)
        var url = "ftls/search";
        Object.keys(formData).forEach((key, i) => {
          if (i === 0) {
            url += `?${key}=${formData[key]}`;
          } else {
            url += `&${key}=${formData[key]}`;
          }
        });
        get(url).then(res =>{
          console.log(res)
          if(res && res.length > 0){
            setFormData({})
            setData(res)
          }
        }).catch(err => {
          console.error(err)
        })
      }
    
      const handleChange = (e) => {
        console.log(e.target, 'e.target')
        if(e.target.name && e.target.value){
            setFormData(prev=>{
            return {...prev, [e.target.name]: e.target.value}
          })
         
        }else if(e.target.name){
            setFormData(prev=>{
            return {...prev, [e.target.name]: ""}
          }) 
        }
      }

  return (
    <div className="py-10 text-left">
      <TitlePrimary title={"Select an RTL"} />
      <form onChange={handleChange} onSubmit={handleSubmit} className="flex flex-col gap-6 mt-10">
        <h6>Search Option</h6>
        <div className="flex gap-6">
          <div className="w-[200px]">
            <Input label="First Name" id="param1" name="param1" value={formData.param1 ? formData.param1 : ''} />
          </div>
          <div className="w-[200px]">
            <Input label="Last Name" id="param2" name="param2" value={formData.param2 ? formData.param2 : ''}/>
          </div>
        </div>
        <div className="w-[200px]">
          <Input label="Review ID" id="review_id" name="review_id" value={formData.review_id ? formData.review_id:  ''}/>
        </div>
        <div className="w-[200px]">
          <Input label="RTL" id="rtl" name="rtl" value={formData.review_id ? formData.review_id: ''}  />
        </div>

        <div className="flex gap-6">
          <div className="w[200px]">
            <PrimaryButton
              type="submit"
              label="Search Now"
              className={"px-3 py-2"}
            />
          </div>
          <div className="w[200px]">
            <TertiaryButton
              type="button"
              label="Add New"
              className={"px-3 py-2"}
              onClick={() => navigate('/rtl/add-new')}
            />
          </div>
        </div>
      </form>
      <div className="flex flex-col gap-10 text-left mt-10">
        <h6 className="font-semibold text-[18px] m-0 p-0">
          Search Results ( {data.length ? data.length : 0} )
        </h6>
        <SearchResults data={data} />
      </div>
    </div>
  );
}
