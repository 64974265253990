import React from "react";
import TextWithLabel from "../../../../inputs/text";

export default function TripInfo() {
  return (
    <div className="p-7 flex flex-col gap-10">
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="Trip Notes" value="n/a" />
        </div>

        <div className="w-[400px]">
          <TextWithLabel
            label="Trip ID/Grantee"
            value="166TXB4E Kids are First, Inc / 06CH9980"
          />
        </div>
      </div>
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="Trip Dates" value="4/19/16-04/22/16" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Processed On" value="4/19/2016 9:38:28 AM" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Packets Mailed On" value="" />
        </div>

        <div className="w-[200px]">
          <TextWithLabel label="Review Type" value="EnvHS" />
        </div>
      </div>
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="Fellow" value="No" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Work Phone" value="(000) 000-0000" />
        </div>
        <div className="w-[400px]">
          <TextWithLabel
            label="Traine/Presentor Component Area"
            value="EnvHS"
          />
        </div>
      </div>
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="RLT" value="Jennifer Johnston" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Phone" value="Phone" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="RP" value="Jamie Wiesner" />
        </div>
      </div>
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="TER Date" value="4/28/2016" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="New TER Date" value="n/a" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="TER Processed By" value="Supa Barnwanijakul " />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="TER Processed On" value="5/3/16 7:44:02 PM" />
        </div>
      </div>
      <div className="flex gap-10">
        <div className="w-full">
          <TextWithLabel
            label="TER Notes"
            value="DATE: 4/19/2016 9:34:04AM Type: Replacement Sub Type: Reason: Replaced Courtney Pringle Entered By: Ymalai"
          />
        </div>
      </div>
    </div>
  );
}
