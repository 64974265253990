import React from "react";
import TertiaryButton from "../../components/buttons/tertiary";
import TextWithLabel from "../../components/inputs/text";
import DataTable from "react-data-table-component";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from '../../components/typhography/title-primary'


const data = [
  {
    hotelName:
      "Ramada Inn Airport South 6800 South Interststr 35 Oklahoma City, OK 73149 (405)631-3321",
    hotel: "Hotel",
    conf: "P40900-00",
    conus: "",
    bookedRate: "Booked Rate",
    lodging: "$5.65",
    tax: "$5.65",
    perdiem: "$5.65",
    costLetterReq: "No",
  },
  {
    hotelName:
      "Ramada Inn Airport South 6800 South Interststr 35 Oklahoma City, OK 73149 (405)631-3321",
    hotel: "Hotel 2",
    conf: "P40900-00",
    conus: "",
    bookedRate: "Booked Rate",
    lodging: "$5.65",
    tax: "$5.65",
    perdiem: "$5.65",
    costLetterReq: "No",
  },
  {
    hotelName:
      "Ramada Inn Airport South 6800 South Interststr 35 Oklahoma City, OK 73149 (405)631-3321",
    hotel: "Hotel 3",
    conf: "P40900-00",
    conus: "",
    bookedRate: "Booked Rate",
    lodging: "$5.65",
    tax: "$5.65",
    perdiem: "$5.65",
    costLetterReq: "No",
  },
];

export default function GranteeHistory() {

  const columns = [
    {
      name: (
        <div className="uppercase text-md text-gray-600">
          RENTAL CARS/RENTAL AGENCY
        </div>
      ),
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">TRAVELER</div>,
      selector: (row) => row.training,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">TRAVELER</div>,
      selector: (row) => row.training,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase text-md text-gray-600">
          TRAVEL MODE/FLIGHT ARRIVAL INFO
        </div>
      ),
      selector: (row) => row.training,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase text-md text-gray-600">
          COMPONENT AREA/FLIGHT DEPARTURE INFO
        </div>
      ),
      selector: (row) => row.training,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">HOTEL NIGHTS</div>,
      selector: (row) => row.training,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">HOTEL</div>,
      selector: (row) => row.training,
      sortable: true,
    },
  ];
  const tableData = [
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
    },
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
    },
  ];

  return (
    <div className="flex flex-col gap-10 text-left mb-20">
      <PrimaryBreadCrumbs label="Grantee History" path="/reviews" />
      
      <div className="flex justify-between">
      <TitlePrimary title={"Team Summary"} />
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex">
          <h6 className="text-[20px] font-[500]">Grantee History</h6>
          <div className="ml-auto">
            <div className="w-[200px]">
              <TertiaryButton label={"Export as PDF"} className={"py-2 px-3"} />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex gap-8">
            <div className="w-[400px]">
              <TextWithLabel
                label="Review ID/Grantee"
                value="03IOK043 Citizen Potawatomi Mation Early Head Start Program"
              />
            </div>
            <div className="w-[400px]">
              <TextWithLabel
                label="FTL"
                value="Ms.Cynthia Walker (202)205-8820"
              />
            </div>
          </div>
          <div className="flex gap-8">
            <div className="w-[400px]">
              <TextWithLabel
                label="FTL Meeting Info"
                value="4/6/2023 @ 7:00:00 PM, Hotel"
              />
            </div>
            <div className="w-[400px]">
              <TextWithLabel label="Review Dates" value="04/06/03-04/11/03" />
            </div>
          </div>
        </div>

        {data.map((d, i) => {
          return (
            <div key={i} className="flex flex-col gap-6">
              <div className="w-[400px]">
                <h6 className="text-[20px] font-[500]">{d.hotel}</h6>
              </div>
              <div className="w-[400px]">
                <TextWithLabel value={d.hotelName} />
              </div>
              <div className="flex gap-8">
                <div className="w-[400px]">
                  <TextWithLabel label="Conf #:" value={d.conf} />
                </div>
                <div className="w-[400px]">
                  <TextWithLabel label="Conus Info" value="" />
                </div>
              </div>
              <div className="flex gap-8">
                <div className="w-[400px]">
                  <TextWithLabel label="Booked Rate" value={d.bookedRate} />
                </div>
                <div className="w-[400px]">
                  <TextWithLabel label="Lodging" value={d.lodging} />
                </div>
              </div>
              <div className="flex gap-8">
                <div className="w-[400px]">
                  <TextWithLabel label="Tax" value={d.tax} />
                </div>
                <div className="w-[400px]">
                  <TextWithLabel label="Per Diem" value={d.perdiem} />
                </div>
              </div>
              <div className="flex gap-8">
                <div className="w-[400px]">
                  <TextWithLabel
                    label="At Cost Letter Required?"
                    value={d.costLetterReq}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex flex-col gap-10 text-left">
        <div className="flex">
          <div className="flex gap-3">
            <h6 className="text-gray-600 text[20px]">Review ID/Grantee</h6>
            <h6 className="font[800] text[20px]">03IOK043</h6>
          </div>
          <div className="ml-auto flex gap-6">
            <div className="flex gap-3">
              <p className="text-gray-600 text[16px]">Total Team Size:</p>
              <p className="font[800] text[16px]">0</p>
            </div>
            <div className="flex gap-3">
              <p className="text-gray-600 text[16px]">Total Rental Cars:</p>
              <p className="font[800] text[16px]">0</p>
            </div>
          </div>
        </div>
        <DataTable columns={columns} data={tableData} />
      </div>
    </div>
  );
}
