import React from "react";

import InputDate from "../../inputs/date";
import TertiaryButton from "../../buttons/tertiary";
import DataTable from "react-data-table-component";

export default function NewReviews() {
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">SUBMIT TYPE</div>,
      selector: (row) => row.submitType,
      sortable: true,
      cell:(row) => {
        const type=row.submitType
        return <div className={`${type == "Final"? 'bg-[#E0E7FF]' : type==="Partial"? 'border border-indigo-600' : ''} p-2 rounded-full text-indigo-600`}>
            {type}
        </div>
      }
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW ID</div>,
      selector: (row) => row.reviewID,
      sortable: true,
      cell:(row) => {
        return <div className="text-indigo-600 font-semibold">{row.reviewID}</div>
      }
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW DATE</div>,
      selector: (row) => row.reviewDate,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">Grantee name</div>,
      selector: (row) => row.granteeName,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">QC STATUS</div>,
      selector: (row) => row.qcStatus,
      sortable: true,
      cell:(row) => {
        const type=row.qcStatus
        return <div className={`${type == "In Progress"? 'bg-[#FEF3C7] text-[#92400E]' : type==="Not Started"? 'bg-[#E5E7EB] text-[#030712]' : ''} p-2 rounded-full `}>
            {type}
        </div>
      }
    },
    {
      name: <div className="uppercase text-md text-gray-600">SENT BY</div>,
      selector: (row) => row.sentBy,
      sortable: true,
      cell:(row) => {
        return <div className="text-indigo-600 font-semibold">{row.sentBy}</div>
      }
    },
    {
      name: <div className="uppercase text-md text-gray-600">RECEIVED ON</div>,
      selector: (row) => row.recievedOn,
      sortable: true,
    },
  ];
  const data = [
    {
      submitType: "Final",
      reviewID: "01234F2C",
      reviewDate: "01234F2C",
      granteeName: "Putnam County Board of Education",
      qcStatus: "In Progress",
      sentBy: "AJOHNSTON",
      recievedOn: "3/1/24, 11:45 AM",
    },
    {
      submitType: "Partial",
      reviewID: "01234F2C",
      reviewDate: "01234F2C",
      granteeName: "Putnam County Board of Education",
      qcStatus: "Not Started",
      sentBy: "AJOHNSTON",
      recievedOn: "3/1/24, 11:45 AM",
    },
  ];
  return (
    <div className="flex flex-col gap-6">
      <h6 className="font-semibold text-[20px]">New Reviews</h6>
      <div className="flex gap-6">
        <div className="w-[200px]">
          <InputDate label="From Date" />
        </div>
        <div className="w-[100px] mt-auto">
          <TertiaryButton label="Filter" className={"p-2"} />
        </div>
      </div>
      <DataTable columns={columns} data={data} />
    </div>
  );
}
