import { SET_ROLES, SET_USER_INFO } from "./constants";

export const intialState = {
  userDetails: {},
  roles: [],
};

export const reducer = (state = intialState, action) => {
  switch (action.type) {
    case SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    case SET_USER_INFO:
      return {
        ...state,
        userDetails: action.payload,
      };

    default:
      break;
  }
};
