import React from "react";
import CarRentalsTab from "../../components/travel/car-rentals-tab/car-rentals-tab";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from '../../components/typhography/title-primary'


export default function RentalDetails() {

  return (
    <div className="flex flex-col gap-7 text-left">
      <PrimaryBreadCrumbs label="Car Rental Search" path="/travel?tab=1" />
      <TitlePrimary title={"Rental Car Reconciliation"} />
      <h6 className="font-semibold text-[18px] mt-2">Rental Car Info</h6>
      <div>
        <CarRentalsTab />
      </div>
    </div>
  );
}
