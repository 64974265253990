import React from "react";
import DataTable from "react-data-table-component";
import TextWithLabel from "../../../../inputs/text";


export default function HotelExpenses() {
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">DATE RANGE</div>,
      selector: (row) => row.item,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">PER NIGHT RATE</div>,
      selector: (row) => row.rate,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600"># NIGHTS</div>,
      selector: (row) => row.days,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">INTERNET</div>,
      selector: (row) => row.encumbrance,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">ALLOWABLE PER DIEM</div>,
      selector: (row) => row.cashAdvance,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">ENCUMBRANCE</div>,
      selector: (row) => row.encumbrance,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">CASH ADVANCE</div>,
      selector: (row) => row.encumbrance,
      sortable: true,
    },
  ];
  const data = [
    {
      item: "4/18-16-4/21/16",
      rate: "$375.00",
      days: "4",
      encumbrance: "$1,500",
      cashAdvance: "$95.20",
    },
    {
      item: "4/18-16",
      rate: "$64",
      days: "1.75",
      encumbrance: "$112.00 X 0.85",
      cashAdvance: "",
    },
  ];
  return (
    <div className="p-7 flex flex-col gap-10">
        <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="Hotel Name" value="Hampton Inn" />
        </div>

        <div className="w-[400px]">
          <TextWithLabel
            label="Hotel Confirmation #"
            value="xxxxxxxxxxxxxxxxxxxxxxx"
          />
        </div>
      </div>
      <div>
        <DataTable columns={columns} data={data} pagination />
      </div>
    </div>
  );
}
