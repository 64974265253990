import React from "react";
import TextWithLabel from "../../../inputs/text";
import OnlineTrainings from "./online-trainings";
import Input from "../../../inputs/input";
import PrimaryButton from "../../../buttons/primary";
import SecondaryButton from "../../../buttons/secondary";
import Select from "../../../inputs/select";
import InputDate from "../../../inputs/date";
import CheckBoxGroup from "../../../inputs/checkbox-group";

export default function Edit({setEdit}) {
  return (
    <div className="flex flex-col gap-10 text-left mb-20">
      <div className="flex gap-10">
        <div className="w-[200px]">
          <Input label="Danya ID" value="XXX-XX-5543" />
        </div>
      </div>
      <div className="flex gap-10">
        <div className="w-[88px]">
          <Input label="Title" value="Mrs" />
        </div>
        <div className="w-[200px]">
          <Input label="First Name" value="Susan" />
        </div>
        <div className="w-[88px]">
          <Input label="MI" value="M" />
        </div>
        <div className="w-[200px]">
          <Input label="Last Name" value="Aichele" />
        </div>
        <div className="w-[200px]">
          <Input label="Sufix" value="Junior" />
        </div>
      </div>
      <div className="flex gap-10">
        <div className="w-[200px]">
          <Input label="Company Name" value="-" />
        </div>
      </div>

      <div>
        <div className="flex flex-col gap-10">
          <div className="w-[200px]">
            <TextWithLabel label="Deficiency Status" />
          </div>
          <div className="flex gap-10">
            <div className="w-[200px]">
              <Select label="Reviewer Status" options={[]} />
            </div>
            <div className="w-[200px]">
              <InputDate label="Inactive Date" value="10/15/2023" />
            </div>
          </div>
          <div>
            <div className="w-[600px]">
              <Input label="Reason" value="" />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[400px]">
              <Input label="Home Address" value="534 Fernon Street" />
            </div>
            <div className="w-[200px]">
              <Input label="City" value="Philadelphia" />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[200px]">
              <Input label="State" value="PA" />
            </div>
            <div className="w-[200px]">
              <Input label="Zip Code" value="Philadelphia" />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[200px]">
              <Input label="Home Phone/Fax " value="(215) 755-6873" />
            </div>
            <div className="w-[200px]">
              <Input label="Cellular" value="(215) 755-6873" />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[200px]">
              <Input label="Email Address" value="saichle@phila.k12.pa.us" />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[200px]">
              <Input label="Vendor ID" value="-" />
            </div>
            <div className="w-[200px]">
              <Input label="Direct Deposit" value="No" />
            </div>
            <div className="w-[200px]">
              <Input label="NAV Vendor ID" value="-" />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[200px]">
              <Input label="Reviewer Type" value="Peer" />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-10">
        <div className="flex gap-10">
          <div className="w-[400px]">
            <Select label="Employment Status" options={[]} />
          </div>
          <div className="w-[200px]">
            <Input label="Fed/EHS. Grantee ID" value="03CH0208" />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[400px]">
            <Input
              label="Employer Name"
              value="Philadelphia School District - PreKindergarten HS"
            />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[200px]">
            <Input label="Work Address" value="440 N. Broad Street" />
          </div>
          <div className="w-[200px]">
            <Input label="City" value="Philadelphia" />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[200px]">
            <Input label="State" value="PA" />
          </div>
          <div className="w-[200px]">
            <Input label="Zip Code" value="19130" />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[200px]">
            <Input label="Phone" value="(215) 400 - 5671" />
          </div>
          <div className="w-[200px]">
            <Input label="Fax" value="" />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-10">
        <div className="flex gap-10">
          <div className="w-[400px]">
            <Input label="FedEx Address" value="-" />
          </div>
          <div className="w-[200px]">
            <Input label="City" value="-" />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[200px]">
            <Input label="State" value="-" />
          </div>
          <div className="w-[200px]">
            <Input label="Zip Code" value="-" />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-10">
        <div className="flex gap-10">
          <div className="w-[200px]">
            <Input label="Airport" value="Philadelphia,PA" />
          </div>
        </div>
        <div className="w-full">
          <CheckBoxGroup
            label="Content Area"
            groups={[
              {
                label: "ECDC",
                value: "ECDC",
              },
              {
                label: "MH",
                value: "MH",
              },
            ]}
          />
        </div>
      </div>
      <div className="flex flex-col gap-10">
        <div className="w-full">
          <CheckBoxGroup
            label="Regions"
            groups={[
              {
                label: "01",
                value: "01",
              },
              {
                label: "02",
                value: "02",
              },
            ]}
          />
        </div>
        <div className="flex flex-col gap-7">
          <TextWithLabel label="Test Status" />
          <div className="flex gap-10">
            <div className="w-[200px]">
              <Select label="Computer Literacy" options={[]} />
            </div>
            <div className="w-[400px]">
              <Input label="Comments" value="" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-10">
        <TextWithLabel label="Online Trainings Fy 2011 (Mm/Dd/Yy)" />
        <OnlineTrainings />
      </div>
      <div className="flex flex-col gap-10">
        <div className="flex gap-10">
          <div className="w-[200px]">
            <Input label="Exclude From" value="" />
          </div>
          <div className="w-[200px]">
            <Input label="Ytd Declines" value="0" />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[200px]">
            <Input label="Home Region" value="3" />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[200px]">
            <Input label="Email Address" value="Saichle@Phila.K12.Pa.Us" />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[200px]">
            <Input label="Work Email Address" value="Saichle@Phila.K12.Pa.Us" />
          </div>
        </div>
      </div>
      <div className="flex gap-2 mt-10">
        <SecondaryButton
          className={"py-3 px-10"}
          onClick={() => setEdit(false)}
          label={"Cancel"}
        />
        <PrimaryButton
          type="submit"
          className={"py-3 px-10"}
          label={"Update"}
        />{" "}
      </div>
    </div>
  );
}
