import React from "react";
import DataTable from "react-data-table-component";

export default function SearchResultsTable({handleCheckBox, data}) {
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">DANYA ID</div>,
      selector: (row) => row.ssn,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEWER</div>,
      selector: (row) => row.fname,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">EMAIL</div>,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">ADD TO REVIEW</div>,
      selector: (row) => row.reviewers_uid,
      sortable: true,
      cell:(row, i) =>{
       
        return <div>
            <input type="checkbox" id={i} checked={row.selected} onClick={handleCheckBox}/>
        </div>
      }
    },
  ];
  
  return (
    <DataTable columns={columns} data={data} pagination />
  );
}
