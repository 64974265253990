import React, { useState } from "react";
import Select from "../../components/inputs/select";
import TrainingReviews from "../../components/reports/trainingReviews";
import ExpenseInquiry from "../../components/reports/expenseInquiry";
import OutstandingReviewerPayments from "../../components/reports/outstandingReviewerPayments";
import GranteeHistory from "../../components/reports/granteeHistory";
import ReviewTeamSummary from "../../components/reports/reviewTeamSummary";
import ReviewerHistory from "../../components/reports/reviewerHistory";
import HotelHistory from "../../components/reports/hotelHistory";
import ReviewerHolds from "../../components/reports/reviewerHolds";
import UnprocessedReviews from "../../components/reports/unprocessedReviews";
import TitlePrimary from '../../components/typhography/title-primary'


const reportTypes = [
  {
    label: "Unprocessed Reviews",
    value: "unprocessedReviews",
  },
  {
    label: "Review Team Summary",
    value: "reviewTeamSummary",
  },
  {
    label: "Training Reviews",
    value: "trainingReviews",
  },
  {
    label: "Expense Inquiry",
    value: "expenseInquiry",
  },
  {
    label: "Outstanding Reviewer Payments",
    value: "outstandingReviewerPayments",
  },
  {
    label: "Reviewer Holds",
    value: "reviewerHolds",
  },
  {
    label: "Grantee History",
    value: "granteeHistory",
  },
  {
    label: "Reviewer History",
    value: "reviewerHistory",
  },
  {
    label: "Hotel History",
    value: "hotelHistory",
  },
];

export default function Reports() {
  const [selectedType, setType] = useState("unprocessedReviews");
  const handleType = (e) => {
    setType(e.target.value);
  };

  return (
    <div className="flex flex-col gap-10 text-left">
      <div>
      <TitlePrimary title={"Reports"} />
      </div>
      <div className="flex flex-col gap-6">
        <h4 className="font-semibold text-[18px]">Generate Report</h4>
        <div className="w-[400px]">
          <Select
            onChange={handleType}
            label="Report Type"
            options={reportTypes}
          />
        </div>
        {selectedType === "unprocessedReviews" ? (
          <UnprocessedReviews />
        ) : selectedType === "reviewTeamSummary" ? (
          <ReviewTeamSummary />
        ) : selectedType === "trainingReviews" ? (
          <TrainingReviews />
        ) : selectedType === "expenseInquiry" ? (
          <ExpenseInquiry />
        ) : selectedType === "outstandingReviewerPayments" ? (
          <OutstandingReviewerPayments />
        ) : selectedType === "reviewerHolds" ? (
          <ReviewerHolds />
        ) : selectedType === "granteeHistory" ? (
          <GranteeHistory />
        ) : selectedType === "reviewerHistory" ? (
          <ReviewerHistory />
        ) : selectedType === "hotelHistory" ? (
          <HotelHistory />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
