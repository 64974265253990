import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import RadioGroup from "../../../../inputs/radio-group";
import Select from "../../../../inputs/select";
import { get } from "../../../../../services/index";

export default function GeneralTISTab({ data, errors }) {
  const { id } = useParams();

  const [serviceAreas, setServiceArea] = useState([]);
  const [serviceAreas2, setServiceArea2] = useState([]);

  useEffect(() => {
    get(`reviews/tis_input_servicearea?review_id=${id}`)
      .then((res) => {
        console.log(res, "ress");
        if (res && res.length > 0) {
          setServiceArea(
            res?.map((d) => {
              var temp = {};
              temp["label"] = d?.Service_Area_Desc;
              temp["value"] = d?.New_Service_Area;
              return temp;
            })
          );
          setServiceArea2(
            res?.map((d) => {
              var temp = {};
              temp["label"] = d?.Service_Area_Desc;
              temp["value"] = d?.New_Service_Area;
              return temp;
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  console.log(data?.CashAdvanceSent, "data?.CashAdvanceSent");

  return (
    <div className="flex w-full text-left p-8 gap-10">
      <div className="w-3/6 flex flex-col gap-8">
        <RadioGroup
          label="Cash Advance Reqd."
          name="CashAdvanceSent"
          id="CashAdvanceSent"

          groups={[
            {
              id: "CashAdvanceSent",
              label: "Yes",
              name: "CashAdvanceSent",
              value: "true",
              checked: `${data?.CashAdvanceSent}` == "true"
            },
            {
              id: "CashAdvanceSent",
              label: "No",
              name: "CashAdvanceSent",
              value: "false",
              checked: `${data?.CashAdvanceSent}` == "false"
            },
          ]}
        />
        <div className="w-4/6">
          <Select
            label="Service Area"
            name="ComponentArea"
            id="ComponentArea"
            options={serviceAreas}
            selected={data?.ComponentArea}
            required
            isErrorOccurred={errors?.ComponentArea}
          />
        </div>
        <div className="w-3/6">
          <Select
            label="Sub Team"
            name="subTeamNumber"
            id="subTeamNumber"
            options={[...Array(11)].map((_, i) => {
              return { label: `${i}`, value: i };
            })}
            value={data?.subTeamNumber ?? ""}
            selected={data?.subTeamNumber ?? ""}
          />
        </div>

        <RadioGroup
          label="Bilingual placement"
          name="BilingualPlacement"
          id="BilingualPlacement"

          groups={[
            {
              id: "BilingualPlacement",
              label: "Yes",
              name: "BilingualPlacement",
              value: "YES",
              checked: `${data?.BilingualPlacement}` == "YES"
            },
            {
              id: "BilingualPlacement",
              label: "No",
              name: "BilingualPlacement",
              value: "NO",
              checked: `${data?.BilingualPlacement}` == "NO"
            },
          ]}
        />
      </div>
      <div className="w-3/6 flex flex-col gap-8">
        <RadioGroup
          label="E-Ticket"
          name="e_tickets"
          id="e_tickets"
          checked={data?.e_tickets}
          groups={[
            {
              id: "e_tickets",
              label: "Yes",
              name: "e_tickets",
              value: "true",
              checked: `${data?.e_tickets}` == "true"
            },
            {
              id: "e_tickets",
              label: "No",
              name: "e_tickets",
              value: "false",
              checked: `${data?.e_tickets}` == "false"
            },
          ]}
        />
        <div className="w-4/6">
          <Select
            label="Service Area II"
            name="componentAreaII"
            id="componentAreaII"
            options={serviceAreas2}
            selected={data?.componentAreaII}
          />
        </div>
      </div>
    </div>
  );
}
