import React from "react";

const CheckBox = ({ label, alignmentType, ...props }) => {
  return (
    <div className="flex flex-col gap-3">
      {label && (
        <label className="text-text-secondary font-[400] text-[14px]">
          {label}
        </label>
      )}
      <div>
        <div
          className={`flex gap-3 ${
            alignmentType && alignmentType === "col" ? "flex-col" : "flex-wrap"
          }`}
        >
          <input
            type="checkbox"
            className="shrink-0 mt-0.5 border-gray-200 rounded-full text-indigo-600 focus:text-indigo-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-indigo-600 dark:checked:border-indigo-600 dark:focus:ring-offset-gray-800"
            {...props}
          />
        </div>
      </div>
    </div>
  );
};

export default CheckBox;
