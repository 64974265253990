import React from "react";
import Select from "../inputs/select";
import InputDate from "../inputs/date";
import PrimaryButton from "../buttons/primary";
import DataTable from "react-data-table-component";
import './style.css'

const searchTypes = [
  {
    label: "Review Date",
    value: "Review Date",
  },
  {
    label: "Submission Date",
    value: "Submission Date",
  },
];

export default function UnprocessedReviews() {
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW ID</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEW DATES</div>,
      selector: (row) => row.training,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">GRANTEE NAME</div>,
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase text-md text-gray-600">
          UNPROCESSED REVIEWERS
        </div>
      ),
      selector: (row) => row.dates,
      sortable: true,
    },
  ];
  const data = [
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
    },
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
    },
  ];
  return (
    <form className="flex flex-col gap-6">
      <div>
        <div className="flex gap-8">
          <div className="w-[200px]">
            <Select label="Search By" options={searchTypes} />
          </div>
          <div className="w-[200px]">
            <InputDate label="From" />
          </div>
          <div className="w-[200px]">
            <InputDate label="To" />
          </div>
        </div>
      </div>
      <div className="w-[170px]">
        <PrimaryButton label="Search" className={"px-3 py-2"} />
      </div>
      <div className="flex flex-col gap-6 text-left">
        <h6 className="text-[20px] font-[500]">Search Results</h6>
        <DataTable
          columns={columns}
          data={data}
          expandableRows={true}
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </form>
  );
}

const ExpandedComponent = () => {
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">REVIEWER</div>,
      selector: (row) => row.trainingID,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">TER RECEIVED DATE</div>,
      selector: (row) => row.training,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase text-md text-gray-600">
          ISSUE DESCRIPTION
        </div>
      ),
      selector: (row) => row.dates,
      sortable: true,
    },
  ];
  const data = [
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
    },
    {
      trainingID: "16STR008",
      training: "Software Training #7",
      types: "Focus Group",
      dates: "6/9/19-6/9/19",
    },
  ];

  return <div className="px-12 bg-[#F1F5F9] subtable-conatiner">
    <DataTable columns={columns} data={data} />
  </div>;
};
