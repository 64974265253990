/* eslint-disable react/prop-types */
import React from 'react'

export default function PrimaryModal({isOpened, children, handleModal}) {
    if(isOpened){
        return (
            <div className='fixed top-0 left-0 right-0 z-10  w-full h-full bottom-0 bg-black/70 flex items-center justify-center'>
                <div className='bg-white shadow-lg  min-w-[700px] min-h-[300px] rounded-md p-3'>
                    <div className='flex'>
                    <div className='ml-auto cursor-pointer' onClick={() => handleModal(false)}>x</div>
                    </div>
                    <div>{children}</div>
                </div>
            </div>
          )
    }else{
        return null
    }
 
}
