import React from 'react'

export default function TextWithBadge({label, value, bg, className}) {
    return (
        <div className="flex flex-col gap-2">
          {label ? (
            <label className="text-text-secondary font-[400] text-[14px]">
              {label}
            </label>
          ) : (
            <></>
          )}
            <div >
                  <p className={`font-semibold inline text-[14px] p-2 rounded-xl ${bg? bg: 'bg-purple-400'} ${className}`}>  {value}</p>
            </div>
        </div>
      );
}
