import React from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

const ReviewsDataTable = ({ reviewsData, isLinkDisabled }) => {
  const navigate = useNavigate();
  const handleReviewID = (val) => {
    navigate("/reviews/details/" + val);
  };
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">Review ID</div>,
      selector: (row) => row.review_id,
      sortable: true,
      cell: (row) => {
        if (isLinkDisabled) {
          return <div className="text-md">{row.review_id}</div>;
        } else {
          return (
            <div
              className=" text-indigo-600 text-md cursor-pointer"
              onClick={() => handleReviewID(row.review_id)}
            >
              {row.review_id}
            </div>
          );
        }
      },
    },
    {
      name: <div className="uppercase text-md text-gray-600">Grantee ID</div>,
      selector: (row) => row.fed_grantee_id,
      sortable: true,
    },
    {
      name: "Grantee Name",
      selector: (row) => row.Name,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">Review Date</div>,
      selector: (row) => row.review_date,
      sortable: true,
    },
  ];

  const employees = { accounting: [] };

  // Safely access recordsets and map over them if they exist
  if (
    reviewsData &&
    reviewsData.recordsets &&
    reviewsData.recordsets.length > 0
  ) {
    (reviewsData?.recordsets?.[0] || []).forEach((review) => {
      employees.accounting.push({
        reviewId: review.trip_id, // Ensure correct key is used
        granteeId: review.fed_grantee_id,
        granteeName: review.Name,
        reviewDate: review.review_date,
      });
    });
  }

  return <DataTable columns={columns} data={reviewsData} pagination />;
};

// Adding prop types validation
ReviewsDataTable.propTypes = {
  reviewsData: PropTypes.shape({
    recordsets: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          trip_id: PropTypes.number.isRequired,
          fed_grantee_id: PropTypes.number.isRequired,
          Name: PropTypes.string.isRequired,
          review_date: PropTypes.string.isRequired,
        })
      )
    ),
  }),
};

export default ReviewsDataTable;
