/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

const RadioGroup = ({ label, groups, checked = false, handleChange, id }) => {
  if (groups && groups.length < 0) {
    return <></>;
  }

  console.log(checked, 'checked', groups, label)
  return (
    <div className="flex flex-col gap-3">
      {label ? (
        <label className="text-text-secondary font-[400] text-[14px]">
          {label}
        </label>
      ) : (
        <></>
      )}
      <div>
        <div className="flex gap-3">
          {groups.map((group, i) => {
            return (
              <div key={i} className="flex">
             
                {
                  handleChange ?  <input
                  type="radio"
                  className="shrink-0 mt-0.5 border-gray-200 rounded-full text-indigo-600 focus:text-indigo-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-indigo-600 dark:checked:border-indigo-600 dark:focus:ring-offset-gray-800"
                  id={group.id}
                  checked={id=== group.id &&( checked == null || undefined) ? "false" : checked }
                  name={group.name}
                  value={group.value}
                  onChange={handleChange}
                />  :  <input
                type="radio"
                name={group.name}
                className="shrink-0 mt-0.5 border-gray-200 rounded-full text-indigo-600 focus:text-indigo-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-indigo-600 dark:checked:border-indigo-600 dark:focus:ring-offset-gray-800"
                id={group.id}
                checked={group.checked}
                value={group.value}
              />
                }
                <label
                  htmlFor={group.id+`${i}`}
                  className="text-sm text-gray-500 ms-2 dark:text-neutral-400 cursor-pointer"
                >
                  {group.label}
                </label>
              </div>
            );
          })}

        </div>
      </div>
    </div>
  );
};

RadioGroup.prototype = {
  label: PropTypes.string,
  groups:PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  checked:PropTypes.string.isRequired
};

export default RadioGroup;
