import React from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

const SearchResults = ({ data }) => {
  console.log(data, 'data')
  const navigate = useNavigate();
  const handleReviewID = (val) => {
    navigate("/reviewers/details/" + val);
  };
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">Name</div>,
      selector: (row) => row.fname,
      sortable: true,
      cell: (row) => (
        <div
          className=" text-indigo-600 text-md cursor-pointer"
          onClick={() => handleReviewID(row?.ReviewerId)}
        >
          {row.fname}
        </div>
      ),
    },
    {
      name: <div className="uppercase text-md text-gray-600">Type</div>,
      selector: (row) => row.reviewer_type,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">C/T</div>,
      selector: (row) => row.coach_trainee,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">Status</div>,
      selector: (row) => row.status,
      sortable: true,
      cell:(row) =>{
        if(row && row.status && row.status === "INACTIVE"){
          return <div className="bg-red-100 p-2 rounded-xl font-semibold text-red-800">{row.status}</div>
        }else{
          return <div className="bg-indigo-100 p-2 rounded-xl font-semibold text-indigo-800">{row.status}</div>
        }
      }
    },
    {
      name: <div className="uppercase text-md text-gray-600">TEMP HOLD</div>,
      selector: (row) => row.Temp_Hold,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">FOREIGN LANGUAGE</div>,
      selector: (row) => row.foreignLanguage,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">Email</div>,
      selector: (row) => row.email,
      sortable: true,
    }
  ];

 
  return <DataTable columns={columns} data={data} pagination />;
};



export default SearchResults;
