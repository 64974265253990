import axios from "axios";
import { baseUrl } from "../config/constants";

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: '',
  }
});


export const get = (URI, params) => {
  if(params){
    return axiosInstance
    .get(`/${URI}`, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err)
      return Promise.reject(err);
    });
  }
  return axiosInstance
    .get(`/${URI}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err)
      return Promise.reject(err);
    });
};
export const getAPIWithPositive = (URI) => {
  return axiosInstance
    .get(`/${URI}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err)
      return {message:"error"}
    });
};

export const post = (URI, data) => {
  return axiosInstance
    .post(`/${URI}`, data)
    .then((res) => {
      if(res && res.data){
        return res.data;
      }else{
        return res
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const put = (URI, data) => {
  return axiosInstance
    .put(`/${URI}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
