import React, {useEffect, useState} from "react";
import { useParams, useLocation } from "react-router-dom";
import GranteeViewEditTabDetail from "../../components/grantees/tabs/GranteeViewEditTabDetail";
import PrimaryTab from "../../components/tabs/primary";
import { get } from "../../services";
import { URI_grantee_info } from "../../config/constants";
import GranteeHistory from "../../components/grantees/tabs/grantee-history";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from '../../components/typhography/title-primary'


const GranteeViewEditDetail = () => {
  const { id } = useParams();
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search);
  const defaultTab = urlParams.get('tab');

 const [granteeSearchData, setGranteeSearchData] = useState({});
 const [loading, setLoading] = useState(true)

 useEffect(() => {
  get(`${URI_grantee_info}?grantee_uid=${id}`).then(res=> {
    console.log(res, 'res')
    if(res && res.length>0){
      setLoading(false)
      setGranteeSearchData(()=>{
        return {...res[0]}
      })
    }
   
  }).catch(err => {
    setLoading(false)
    console.error(err)
  })
}, [id]);

  const tabs = [
    {
      label: "View/Edit Details",
      tab:<GranteeViewEditTabDetail granteeSearchData={granteeSearchData} />
    },
    {
      label: "Grantee History",
      tab:<GranteeHistory />
    }
  ]
  return (
    <>
      {
            loading ? <div>...loading</div> : Object.keys(granteeSearchData).length > 0 ? <div className="flex-2">
        
        <PrimaryBreadCrumbs label="Grantee Search" path="/grantees" />

        <div className="flex justify-between mt-3">
          <TitlePrimary title={granteeSearchData?.Name} />
        </div>


        <div className="mt-2">
        
          <PrimaryTab tabs={tabs} selected={defaultTab}/>
        </div>

      </div>: <div>Something went wrong pls try again</div>
          }
     
    </>
  );
};

export default GranteeViewEditDetail;
