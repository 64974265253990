import React from "react";
import { useNavigate } from "react-router-dom";

export default function PrimaryBreadCrumbs({ label, path }) {
  const navigate = useNavigate();
  return (
   <div className="flex">
     <div className="inline-block cursor-pointer" onClick={() => navigate(path)}>
      &lt;  Back to {label}
    </div>
   </div>
  );
}
