import React from "react";
import InputDate from "../../../../inputs/date";
import dayjs from "dayjs";
import Input from "../../../../inputs/input";

export default function FlightInfo({ data, errors }) {
  console.log(data.arrival_arrival_date, 'fligh info', dayjs(data.arrival_arrival_date).format("YYYY-MM-DD"))

  return (
    <div className="flex flex-col gap-8 p-8 text-left">
      <div className="flex gap-8">
        <div className="w-3/6 flex flex-col gap-8">
          <div className="w-4/6">
            <InputDate
              label="Arrival Date & Time"
              name="arrival_arrival_date"
              id="arrival_arrival_date"
              defaultValue={dayjs(data?.arrival_arrival_date).format("YYYY-MM-DD")}
              isErrorOccurred={errors.arrival_arrival_date}
            />
          </div>
          <div className="w-4/6">
            <Input label="Arrival Airlines" id="arrival_airline" name="arrival_airline" value={data?.arrival_airline} />
          </div>
        </div>
        <div className="w-3/6 flex flex-col gap-8">
          <div className="w-4/6">
            <InputDate label="Departure Date & Time"
              name="depart_depart_date"
              id="depart_depart_date"
              defaultValue={dayjs(data?.depart_depart_date).format("YYYY-MM-DD")}
              isErrorOccurred={errors.depart_depart_date}
            />
          </div>
          <div className="w-4/6">
            <Input label="Departure Airlines" id="departure_airline" name="departure_airline" value={data?.departure_airline} />
          </div>
        </div>
      </div>
    </div>
  );
}
