import React, {useEffect, useState} from "react";
import TextWithLabel from "../../../inputs/text";
import HistoryTable from "./history-table";
import { get } from "../../../../services";

export default function ReviewerHistory({id, reviewer}) {
  const [historyData, setHistoryDatya] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {

    get(`reviewers/fetchReviewersHistory?historyType=past&reviewers_uid=${id}`)
    .then(res => {
        if(res && res.length >0){
          setHistoryDatya(res)
        }
        setLoading(false)
    }).catch(err => {
      setLoading(false)
      console.error(err)
    })

   
  }, [])

  if(loading){
    return <div>...loading</div>
  }

  console.log(reviewer, 'historyData')
  var name = ''
  if(reviewer.Title){
    name += reviewer.Title
  }
  if(reviewer.FName){
    name += ' '+reviewer.FName
  }
  if(reviewer.LName){
    name += ' '+reviewer.LName
  }
  return (
    <div className="flex flex-col gap-10 text-left">
      <h6 className="font-[500] text-[18px] mt-5">Reviewer History</h6>
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="Danya ID" value={reviewer?.SSN} />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Name" value={name} />
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <h6 className="font-[500] text-[16px]">Current Schedules</h6>
        <div>No data to display</div>
      </div>
      {/* <div className="flex flex-col gap-6">
        <h6 className="font-[500] text-[16px]">Being Tracked</h6>
        <div>No data to display</div>
      </div> */}
      <div className="flex flex-col gap-6">
        <h6 className="font-[500] text-[16px]">History</h6>
        <HistoryTable id={id} data={historyData}/>
      </div>
    </div>
  );
}
