// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subtable-conatiner .rdt_Table{
  background: #F1F5F9 !important;
  border: none !important;
}

.subtable-conatiner .rdt_TableHeadRow{
    background: #F1F5F9 !important;
}

.subtable-conatiner .rdt_TableRow{
    background: #F1F5F9 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/reports/style.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,uBAAuB;AACzB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".subtable-conatiner .rdt_Table{\n  background: #F1F5F9 !important;\n  border: none !important;\n}\n\n.subtable-conatiner .rdt_TableHeadRow{\n    background: #F1F5F9 !important;\n}\n\n.subtable-conatiner .rdt_TableRow{\n    background: #F1F5F9 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
