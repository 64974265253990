import React from "react";
import TextWithLabel from "../../../../inputs/text";

export default function TERDetails() {
  return (
    <div className="p-7 flex flex-col gap-10">
      <div className="flex gap-10">
        <div className="w-[200px]">
          <TextWithLabel label="Check #" value="n/a" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Check Date" value="n/a" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel
            label="Check Amount"
            value="n/a"
          />
        </div>
      </div>
    </div>
  );
}
