import React from "react";
import DataTable from "react-data-table-component";
import SecondaryButton from "../../../buttons/secondary";
import { useNavigate } from "react-router-dom";
import { ReactComponent as TrashSvg } from "../../../../assets/icons/trash.svg";

export default function ReviewersTable({ reviewInfo }) {
  const navigate = useNavigate();
  const handleReviewID = (val) => {
    navigate("/reviewer-status/" + val);
  };
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">DANYA ID</div>,
      selector: (row) => row?.Reviewer_Id,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">REVIEWER</div>,
      selector: (row) => row.rev_name,
      sortable: true,
      cell: (row) => (
        <div
          className=" text-indigo-600 text-md"
          onClick={() => handleReviewID(row.reviewers_uid)}
        >
          {row.rev_name}
        </div>
      ),
    },
    {
      name: <div className="uppercase text-md text-gray-600">EMAIL</div>,
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">TIS</div>,
      selector: (row) => row?.cashAdvance,
      sortable: true,
      cell: () => {
        return (
          <div>
            <SecondaryButton label="ViewTIS" />
          </div>
        );
      },
    },
    {
      name: <div className="uppercase text-md text-gray-600"></div>,
      selector: (row) => row.ChangeInStatus,
      width: "8%",
      sortable: true,
      cell: (row) => {
        if (row && row.ChangeInStatus == "") {
          return (
            <div>
              <TrashSvg className="text-xs text-red-600" width="20" />
            </div>
          );
        }else {
          return <></>;
        }
      },
    },
  ];
  // const data = [
  //   {
  //       danyaID: "XXX-XX-4532",
  //       reviewer: "Mary Algier",
  //       email: "malgier@yahoo.com",
  //   },
  //   {
  //       danyaID: "XXX-XX-9856",
  //       reviewer: "Mary Smith",
  //       email: "marysmith82@comcast.net",
  //   }
  // ];
  return (
    <div>
      {reviewInfo && reviewInfo.length > 0 ? (
        <DataTable columns={columns} data={reviewInfo} pagination />
      ) : (
        <div>No Reviewers Found</div>
      )}
    </div>
  );
}
