import React from "react";
import TextWithLabel from "../../inputs/text";
import Input from "../../inputs/input";
import PrimaryButton from "../../buttons/primary";

export default function CarRentalsTab() {
  return (
    <div className="flex flex-col gap-6 text-left mb-20">
      <div className="flex gap-6">
        <div className="w-[200px]">
          <TextWithLabel label="Review ID" value="124FL011" />
        </div>
        <div className="w-[400px]">
          <TextWithLabel
            label="Grant Recipient"
            value="Agricultural & Labor Program, Inc.300 Lynchburg Road, Lake Alfred, FL 33850-2576"
          />
        </div>
        <div className="w-[400px]">
          <TextWithLabel
            label="Reviewer"
            value="Agricultural & Labor Program, Inc.300 Lynchburg Road, Lake Alfred, FL 33850-2576"
          />
        </div>
      </div>
      <div className="flex gap-6">
        <div className="w-[200px]">
          <TextWithLabel label="Review Type" value="Triennial" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Estimated Cost" value="$281.98" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Agency Name" value="NATIONAL" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Car Size" value="Full Size" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Reconciled On" value="n/a" />
        </div>
      </div>
      <div className="flex gap-6">
        <div className="w-[200px]">
          <TextWithLabel label="Reconciled On" value="n/a" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Reconciled By" value="n/a" />
        </div>
        <div className="w-[200px]">
          <TextWithLabel label="Misc. C/A Amount" value="$0.00" />
        </div>
      </div>
      <div className="flex flex-col gap-6 ">
        <h6 className="font-semibold text-[18px] mt-2">Reconciliation</h6>
        <div className="w-[200px]">
          <Input label="Actual Car Rental Charges" />
        </div>
        <div className="w[100px] mt-5">
          <PrimaryButton label="Update" className={"px-3 py-2"} />
        </div>
      </div>
    </div>
  );
}
