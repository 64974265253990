import React from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

const ReviewsDataTableIncomplete = ({ reviewsData }) => {
  const navigate = useNavigate();
  const handleReviewID = (val) => {
    navigate("/reviews/details/" + val);
  };
  const columns = [
    {
      name: <div className="uppercase text-md text-gray-600">Review ID</div>,
      selector: (row) => row.fed_grantee_id,
      sortable: true,
      cell: (row) => (
        <div
          className=" text-indigo-600 text-md cursor-pointer"
          onClick={() => handleReviewID(row.trip_id)}
        >
          {row.fed_grantee_id}
        </div>
      ),
    },
    {
      name: <div className="uppercase text-md text-gray-600">RP</div>,
      selector: (row) => row.RP,
      sortable: true,
    },
    
    {
      name: <div className="uppercase text-md text-gray-600">Name</div>,
      selector: (row) => row.Name,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">Review Type</div>,
      selector: (row) => row.review_type,
      sortable: true,
    },
    {
      name: <div className="uppercase text-md text-gray-600">Review Date</div>,
      selector: (row) => row.review_date,
      sortable: true,
      cell:(row) => {
        if(row.review_date){
          return <div>{row.review_date}</div>
        }else{
          return <div>-</div>
        }
      }
    },
    {
      name: <div className="uppercase text-md text-gray-600">Sub Type</div>,
      selector: (row) => row.sub_type,
      sortable: true,
    },
  ];

  return <DataTable columns={columns} data={reviewsData} pagination />;
};

// Adding prop types validation
ReviewsDataTableIncomplete.propTypes = {
  reviewsData: PropTypes.shape({
    recordsets: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          trip_id: PropTypes.number.isRequired,
          fed_grantee_id: PropTypes.number.isRequired,
          Name: PropTypes.string.isRequired,
          review_date: PropTypes.string.isRequired,
        }),
      ),
    ),
  }),
};

export default ReviewsDataTableIncomplete;
