import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import PrimaryTab from "../../components/tabs/primary";
import ReviewerViewEdit from "../../components/reviewers/tabs/view-edit";
import ReviewerHistory from "../../components/reviewers/tabs/reviewer-history";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TitlePrimary from '../../components/typhography/title-primary'

import { get } from "../../services";

export default function ReviewerDetails() {
  const { id } = useParams();
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search);
  const defaultTab = urlParams.get('tab');
  const [loading, setLoading] = useState(true)
  const [reviewers, setReviwers] = useState({})

  useEffect(() => {

    get(`reviewers/reviewer-details?reviewers_uid=${id}`)
    .then(res => {
        if(res && res.length >0){
          setReviwers(res[0])
        }
        setLoading(false)
    }).catch(err => {
      setLoading(false)
      console.error(err)
    })

   
  }, [])
  

  const tabs = [
    {
      label: "View/Edit Details",
      tab: (
        <div className="mt-0">
          <div
            id="tabs-with-underline-1"
            role="tabpanel"
            aria-labelledby="tabs-with-underline-item-1"
          >
            <ReviewerViewEdit id={id} reviewer={reviewers}/>
          </div>
        </div>
      ),
    },
    {
      label: "Reviewer History",
      tab: (
        <div className="mt-0">
          <div
            id="tabs-with-underline-1"
            role="tabpanel"
            aria-labelledby="tabs-with-underline-item-1"
          >
            <ReviewerHistory />
          </div>
        </div>
      ),
    },
  ];
  console.log(reviewers, 'reviewerssas')
  if(loading){
    return <div>...loading</div>
  }
  return (
    <div className="flex flex-col gap-5">
      <PrimaryBreadCrumbs label="Review Search" path="/reviewers" />

      <div className="flex justify-between">
      <TitlePrimary title={`Reviews: ${id}`} />
      </div>
      <div>
        <PrimaryTab tabs={tabs} selected={defaultTab}/>
      </div>
    </div>
  );
}
