import React from "react";
import PrimaryBreadCrumbs from "../../components/breadcrumbs/primary";
import TextWithLabel from "../../components/inputs/text";
import Input from "../../components/inputs/input";
import Select from "../../components/inputs/select";
import CheckBoxGroup from "../../components/inputs/checkbox-group";
import { useNavigate } from "react-router-dom";
// import DateWithCheckBox from "../../components/inputs/date-with-checkbox";
// import InputDate from "../../components/inputs/date";

import PrimaryButton from "../../components/buttons/primary";
import SecondaryButton from "../../components/buttons/secondary";

const contentAreas = [
  {
    label: "Child Development",
    value: "Child Development",
  },
  {
    label: "Disabilities",
    value: "Disabilities",
  },
  {
    label: "Early Head Start",
    value: "Early Head Start",
  },
  {
    label: "Family And Community Partnerships",
    value: "Family And Community Partnerships",
  },
  {
    label: "Fiscal Management",
    value: "Fiscal Management",
  },
  {
    label: "Health",
    value: "Health",
  },
  {
    label: "Ehs Approved",
    value: "Ehs Approved",
  },
  {
    label: "Maternal Child_health",
    value: "Maternal Child_health",
  },
  {
    label: "Infant Toddler Child Dev",
    value: "Infant Toddler Child Dev",
  },
  {
    label: "Infant Toddler Disabilities",
    value: "Infant Toddler Disabilities",
  },
  {
    label: "Program Design And Management",
    value: "Program Design And Management",
  },
  {
    label: "Bilingual",
    value: "Bilingual",
  },
  {
    label: "Report Co-Ordinator",
    value: "Report Co-Ordinator",
  },
  {
    label: "Willing To Bring Personal Computers",
    value: "Willing To Bring Personal Computers",
  },
  {
    label: "Outcomes Approved",
    value: "Outcomes Approved",
  },
];

const AddReviewer = () => {
const navigate = useNavigate()

  return (
    <form>
      <div>
        <PrimaryBreadCrumbs label="Reviewer Search" path="/reviewers" />
      </div>
      <div className="flex flex-col gap-6 text-left my-10">
        <div>
          <TextWithLabel label="Danya ID" value="XXX-XXX-XXX" />
        </div>
        <div className="flex gap-6">
          <Input label="Title" value="" className="w-[100px]" />
          <Input label="First" value="" />
          <Input label="MI" value="" className="w-[100px]" />
          <Input label="Last" value="" />
          <Input label="Suffix" value="" className="w-[100px]" />
        </div>
        <div className="w-4/6">
          <Input label="Company Name" value="" />
        </div>
        <div className="w-3/6 flex flex-col gap-6">
          <Input label="Home Address 1" value="" />
          <Input label="Home Address 2" value="" />
          <div className="flex gap-6">
            <Input label="City" value="" />
            <Input label="State" value="" className="w-[100px]" />
            <Input label="Zip Code" value="" className="w-[100px]" />
          </div>
          <div className="w-3/6 flex gap-6">
            <Input label="Home Phone/Fax" value="" className="w-[300px]" />
            <Input label="Cellular" value="" className="w-[300px]" />
          </div>
        </div>

        <div className="w-3/6 flex gap-6">
          <Input label="Email Address" value="" className="w-[300px]" />
          {/* <Input label="NAV Vendor ID" value="" className="w-[300px]" /> */}
        </div>
        <div className="w-3/6 flex gap-6">
          <Select options={[]} label="Reviewer Type" className="w-[300px]" />
          {/* <Select
            options={[]}
            label="Employment Status"
            className="w-[300px]"
          /> */}
        </div>
        <div className="w-3/6 flex gap-6">
          {/* <Input label="FED/EHS Grantee ID" value="" className="w-[300px]" /> */}
          <Input label="Employer Name" value="" className="w-[400px]" />
        </div>
        <div className="w-3/6 flex flex-col gap-6">
          <Input label="Work Address 1" value="" />
          <Input label="Work Address 2" value="" />
          <div className="flex gap-6">
            <Input label="City" value="" />
            <Input label="State" value="" className="w-[100px]" />
            <Input label="Zip Code" value="" className="w-[100px]" />
          </div>
          <Input label="Work Phone/Fax" value="" className="w-[300px]" />
        </div>
        <div className="w-3/6 flex flex-col gap-6">
          <Input label="FedEx Address 1" value="" />
          <Input label="FedEx Address 2" value="" />
          <div className="flex gap-6">
            <Input label="City" value="" />
            <Input label="State" value="" className="w-[100px]" />
            <Input label="Zip Code" value="" className="w-[100px]" />
          </div>
        </div>
        <div className="w-3/6 flex flex-col gap-6">
          <Input label="Airport" value="" />
          <Input value="" />
        </div>
        <div className="w-4/6">
          <CheckBoxGroup label="Service Area" groups={contentAreas} />
        </div>
        <div className="w-4/6">
          <CheckBoxGroup
            label="Regions"
            groups={[...Array(12)].map((_, i) => {
              return {
                label: (i < 9 ? "0" : "") + `${i + 1}`,
                value: (i < 9 ? "0" : "") + `${i + 1}`,
              };
            })}
          />
        </div>
        {/* <div className="flex flex-col gap-6">
          <TextWithLabel label="Test Status" />
          <div className="w-3/6 flex gap-6">
            <Select
              options={[]}
              label="Computer Literacy"
              className="w-[300px]"
            />
            <Input label="Comments" value="" className="w-[500px]" />
          </div>
        </div> */}
        {/* <div className="flex flex-col gap-6">
          <TextWithLabel label="Online Trainings FY 2011 Mm/Dd/Yy" />
          <div className="flex gap-6">
            <div className="flex flex-col gap-6">
              <DateWithCheckBox label="ECD" id="" name="" />
              <DateWithCheckBox label="ERSEA" id="" name="" />
              <DateWithCheckBox
                label="Head start onsite Monitoring Program"
                id=""
                name=""
              />
              <DateWithCheckBox label="New TReviewer" id="" name="" />
            </div>
            <div className="flex flex-col gap-6">
              <DateWithCheckBox label="FIS" id="" name="" />
              <DateWithCheckBox label="Infant Toddler Child Dev" id="" name="" />
              <DateWithCheckBox label="Class" id="" name="" />
              <DateWithCheckBox
                label="The 5 stages of a monitoring process"
                id=""
                name=""
              />
            </div>
            <div className="flex flex-col gap-6">
              <DateWithCheckBox label="Family And Community Partnerships/Report Co-Ordinator" id="" name="" />
              <DateWithCheckBox label="OHS Monitoring Software" id="" name="" />
              <DateWithCheckBox
                label="Roles and Responsibilties in FY 2011"
                id=""
                name=""
              />
            </div>
          </div>
        </div> */}

        {/* <div className="w-3/6 flex gap-6">
          <Select
            options={[]}
            label="Admin Hold/Hold Date"
            className="w-[300px]"
          />
        </div>
        <div className="w-3/6">
          <Input label="Hold Reason" value="" />
        </div>
        <div className="w-3/6 flex gap-6">
          <Select options={[]} label="Reviewer Status" className="w-[300px]" />
          <InputDate label="Inactive Date" value="" />
        </div>
        <div className="w-4/6 ">
          <Input label="Reason" value="" />
        </div> */}
        <div className="flex gap-2 my-10">
          <SecondaryButton className={"py-3 px-10"} onClick={() => navigate('/reviewers')} label={"Cancel"} />
          <PrimaryButton
            type="submit"
            className={"py-3 px-10"}
            label={"Update"}
          />{" "}
        </div>
      </div>
    </form>
  );
};

export default AddReviewer;
