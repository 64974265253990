import React from "react";
import CheckBoxGroup from "../../../../inputs/checkbox-group";

export default function PaymentValidation({ data }) {
  const groups = [
    {
      label: "Generate Reviewer PO",
      value: "Generate_DeltekPo",
      checked: data["Generate_DeltekPo"] || false,
      id:"Generate_DeltekPo",
      name:"Generate_DeltekPo"
    },
    {
      label: "Generate COP",
      value: "Generate_COP",
      checked: data["Generate_COP"] || false,
      id:"Generate_COP",
      name:"Generate_COP"
    },
    {
      label: "Add Misc. CA",
      value: "Add Misc. CA",
      checked: data["Add_Misc_CA"] || false,
      id:"Add_Misc_CA",
      name:"Add_Misc_CA"

    },
    {
      label: "Designated Trainee",
      value: "designated_trainee",
      checked: data["designated_trainee"] || false,
      id:"designated_trainee",
      name:"designated_trainee"
    },
    {
      label: "Designated Guide",
      value: "designated_coach",
      checked: data["designated_coach"] || false,
      id:"designated_coach",
      name:"designated_coach"
    },
  ];

  return (
    <div className="w-full p-8">
      <CheckBoxGroup alignmentType="col" groups={groups} />
    </div>
  );
}
