import React, { useState } from "react";
import PrimaryButton from "../../buttons/primary";
import TertiaryButton from "../../buttons/tertiary";
import PrimaryModal from "../../modals/primary";
import SecondaryButton from "../../buttons/secondary";
import Select from "../../inputs/select";
import InputDate from "../../inputs/date";
import TextWithLabel from "../../inputs/text";
import TextWithBadge from "../../inputs/text-with-badge";
import InputToggle from "../../inputs/input-toggle";
import { useNavigate, useParams } from "react-router-dom";


const ProcessReviewEditTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isModalOpened, setModalOpened] = useState(false);

  const [ cAToogleEnabled, setCAToggleEnabled ] = useState(false);

  return (
    <>
      <div className="text-justify font-bold text-xl">Process Review</div>
      <div className="flex flex-col justify-between py-4">
        <div className="px-8 py-4 flex flex-col gap-8">
          <div className="flex flex-row gap-8 text-left">
            <div className="w-60">
              <Select
                label="Select A Reviewer"
                options={[
                  {
                    label: "Review ID",
                    value: "reviewID",
                  },
                  {
                    label: "Grantee ID",
                    value: "granteeID",
                  },
                  {
                    label: "Grantee Name",
                    value: "granteeName",
                  },
                ]}
              />
            </div>
            <div className="w-60">
              <TextWithLabel label="Danya ID" value="Select Reviewer First" />
            </div>
          </div>
          <div className="flex flex-row gap-8 text-left">
            <InputDate label="Date of Request" />
            <InputDate label="Date Check is Needed" />
            <InputToggle label="C/A Amount" checked={cAToogleEnabled} onClick={() => setCAToggleEnabled(!cAToogleEnabled)}/>
            <TextWithLabel label="C/A Amount" value="n/a" />
          </div>
          <div className="flex flex-row gap-8 text-left">
            <TextWithLabel label="Processed On" value="n/a" />
            <TextWithLabel
              label="Transferred to Deltek On"
              value="12/28/2024 13:34:53 PM"
            />
            <TextWithBadge
              label="Status"
              value="Success"
              bg="bg-green-200 text-green-600"
            />
          </div>
        </div>

        <div className="flex flex-row gap-8 text-left pt-4">
          <TertiaryButton
            className={"py-3 px-10"}
            label={"Internal Team Summary"}
          />
          <TertiaryButton className={"py-3 px-10"} label={"Update All"} />
          <PrimaryButton
            className={"py-3 px-10"}
            label={"Update"}
            onClick={() => setModalOpened(true)}
          />
        </div>
      </div>

      <PrimaryModal
        isOpened={isModalOpened}
        handleModal={(e) => setModalOpened(e)}
      >
        <div className="flex flex-col gap-8 w-full p-3">
          <h6 className="p-0 m-0 font-semibold text-[20px] text-center">
            Are you sure you want to update all?
          </h6>
          <p className="p-0 m-0 text-gray-600 text-[16px] text-left">
            These reviewers records have been edited and will be updated if you
            choose to proceed
          </p>
          <div className="text-left">
            <div className="p-0 m-0 font-semibold text-[17px]">
              Regina McCurty <span className="p-0 m-0 font-normal">REN007</span>
            </div>
            <div className="p-0 m-0 font-semibold text-[17px]">
              Regina McCurty <span className="p-0 m-0 font-normal">REN007</span>
            </div>
          </div>
          <div className="flex items-center gap-4 justify-center">
            <SecondaryButton
              onClick={() => setModalOpened(false)}
              label={"Cancel"}
              className={"w-[110px] h-[46px]"}
            />
            <PrimaryButton
              onClick={() => navigate(`/reviews/${id}/send-email`)}
              label={"Update All"}
              className={"w-[110px] h-[46px]"}
            />
          </div>
        </div>
      </PrimaryModal>
    </>
  );
};

export default ProcessReviewEditTab;
